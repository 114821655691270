<template>
  <div>
    <v-tabs :hide-slider="true" v-model="tab" grow background-color="transparent" icons-and-text>
      <v-tab href="#tab-1">
        Personal Details
        <v-icon>mdi-account-box</v-icon>
      </v-tab>

      <v-tab href="#tab-2">
        Professional Details
        <v-icon>mdi-account-details</v-icon>
      </v-tab>
      <v-tab href="#tab-3">
        Skills
        <v-icon>mdi-account-cog</v-icon>
      </v-tab>
      <v-tab href="#tab-4">
        Education
        <v-icon>mdi-school</v-icon>
      </v-tab>
      <v-tab href="#tab-5">
        Career History
        <v-icon>mdi-table-chair</v-icon>
      </v-tab>
      <v-tab href="#tab-6">
        Certificate
        <v-icon>mdi-certificate</v-icon>
      </v-tab>
      <v-tab href="#tab-7">
        Contact
        <v-icon>mdi-phone</v-icon>
      </v-tab>
    </v-tabs>
    <v-row>
      <v-col col="12" lg="12" sm="12">
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="tab-1">
            <PersonalDetail
              :isHiringmanager="isHiringmanager"
              :selectedTeammemberModel="selectedTeammemberModel"
              :isAfterSignUp="false"
              @scrollToElement="scrollToElement"
              @snackbarMessage="snackbarMessage"
              :loading="submitting"
              @submitPersonalDetail="submitPersonalDetail"
              :personalDetailModel="personalDetailModel"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="tab-2">
            <ExperienceDetail
              :isHiringmanager="isHiringmanager"
              :isAfterSignUp="false"
              :formLoading="formLoading"
              :professionalDetailModel="professionalDetailModel"
              :details="details"
              :loading="submitting"
              :teamMember="teamMember"
              :sendReqeustLoading="sendReqeustLoading"
              @scrollToElement="scrollToElement"
              @submitExperienceDetail="submitExperienceDetail"
              @submitExperienceCareerObjectiveDetail="submitExperienceCareerObjectiveDetail"
              @RemoveCareerObjective="RemoveCareerObjective"
              @sendJobRequest="sendJobRequest"
              @getProfessionalDetail="getProfessionalDetail"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="tab-3">
            <v-row>
              <v-col md="6">
                <Skill
                  @snackbarMessage="snackbarMessage"
                  :isHiringmanager="isHiringmanager"
                  @showLimitationMessage="showLimitationMessage"
                  @skillDownload="skillDownload"
                  @showSuccessMessage="showSuccessMessage"
                  @showErrorMessage="showErrorMessage"
                  @skillCertificateChanged="skillCertificateChanged"
                  @getSkills="loadSkillsList"
                  @saveSkill="saveSkill"
                  @remove="remove"
                  @showEditSkill="showEditSkill"
                  @sendAddRequest="sendSkillAddRequest"
                  @saveRoleAndTitle="saveRoleAndTitle"
                  @closeEditModal="closeEditModal"
                  :details="details"
                  :uploadedCertificate="uploadedCertificate"
                  :sendReqeustLoading="sendReqeustLoading"
                  :showEditSkillModal="showEditSkillModal"
                  :addSkillLoading="addSkillLoading"
                  :removeSkillloading="removeSkillloading"
                  :skills="skills"
                  :teammemberSkills="teammemberSkills"
                  :tmModel="teamMember"
                  :saveRoleAndTitleLoading="saveRoleAndTitleLoading"
                />
              </v-col>
              <v-col md="6">
                <LanguageSkillComponent
                  :isHiringmanager="isHiringmanager"
                  @snackbarMessage="snackbarMessage"
                  :isAfterSignUp="false"
                  :formLoading="formLoading"
                  :languageSkills="languages"
                  :selectedTeammemberModel="selectedTeammemberModel"
                  @showSuccessMessage="showSuccessMessage"
                  @showErrorMessage="showErrorMessage"
                  @getLanguageSkills="getLanguageSkills"
                />
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="tab-4">
            <EducationComponent
              @snackbarMessage="snackbarMessage"
              :isHiringmanager="isHiringmanager"
              @scrollToElement="scrollToElement"
              :selectedTeammemberModel="selectedTeammemberModel"
              @showSuccessMessage="showSuccessMessage"
              @showErrorMessage="showErrorMessage"
              :formLoading="formLoading"
              :educations="educations"
              @getEducations="getEducations"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="tab-5">
            <CareerHistory
              :isHiringmanager="isHiringmanager"
              :isAfterSignUp="false"
              @snackbarMessage="snackbarMessage"
              @showSuccessMessage="showSuccessMessage"
              @showErrorMessage="showErrorMessage"
              :selectedTeammemberModel="selectedTeammemberModel"
              @sendJobRequest="sendJobRequest"
              @getCareerHistories="getCareerHistories"
              @scrollToElement="scrollToElement"
              @setCareerHistoryDescriptionState="setCareerHistoryDescriptionState"
              @updateJobTitle="updateJobTitle"
              :formLoading="formLoading"
              :skills="skills"
              :careerHistories="careerHistories"
              :careerHistoryDescription="careerHistoryDescription"
              :teammemberSkills="teammemberSkills"
              :submitting="submitting"
              :details="details"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="tab-6">
            <CertificateComponent
              @snackbarMessage="snackbarMessage"
              :isHiringmanager="isHiringmanager"
              @showSuccessMessage="showSuccessMessage"
              @showErrorMessage="showErrorMessage"
              :selectedTeammemberModel="selectedTeammemberModel"
              :formLoading="formLoading"
              :skills="skills"
              :teammemberSkills="teammemberSkills"
              :certificates="certificates"
              @getCertificates="getCertificates"
              @scrollToElement="scrollToElement"
            />
          </v-tabs-window-item>
          <v-tabs-window-item value="tab-7">
            <ContactComponent
              @snackbarMessage="snackbarMessage"
              :isHiringmanager="isHiringmanager"
              :selectedTeammemberModel="selectedTeammemberModel"
              @showErrorMessage="showErrorMessage"
              :isAfterSignUp="false"
              :addressList="addresses"
              :phoneList="phoneNumbers"
              :addressSendCodeLoading="addressSendCodeLoading"
              :phoneSendCodeLoading="phoneSendCodeLoading"
              :addressLoading="addressLoading"
              :phoneNumberLoading="phoneNumberLoading"
              :addressVerifyLoading="addressVerifyLoading"
              :phoneNumberVerifyLoading="phoneNumberVerifyLoading"
              :tmModel="teamMember"
              :formLoading="formLoading"
              @getContact="getContact"
              @setAddressAsDefault="setAddressAsDefault"
              @setPhoneAsDefault="setPhoneAsDefault"
              @verifyAddress="verifyAddress"
              @verifyPhone="verifyPhone"
              @sendAddressCode="sendAddressCode"
              @sendPhoneCode="sendPhoneCode"
              @removeAddress="removeAddress"
              @scrollToElement="scrollToElement"
              @removePhone="removePhone"
              @saveAddress="saveAddress"
              @savePhone="savePhone"
            />
          </v-tabs-window-item>
        </v-tabs-window>
      </v-col>
    </v-row>

    <v-dialog> </v-dialog>
    <v-dialog v-model="uploadImage" persistent max-width="600px">
      <v-card>
        <v-card-text>
          <v-col align-self="center">
            <v-row justify="center">
              <!-- <v-image-input class="mt-5" v-model="imageData" :image-quality="0.85" clearable image-format="jpeg" hideActions /> -->
            </v-row>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" @click="setImage">
            <v-icon>mdi-check</v-icon>
            Accept</v-btn
          >
          <v-btn color="primary" text @click="uploadImage = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="showConfirm" v-model="showConfirm" max-width="500" @keydown.esc="showConfirm = false">
      <confirm-dialog :message="confirmModel" @confirm="confirmRemove" @cancel="cancelRemove"></confirm-dialog>
    </v-dialog>
    <v-dialog v-if="showJobRequestConfirm" v-model="showJobRequestConfirm" max-width="500" @keydown.esc="showJobRequestConfirm = false">
      <confirm-dialog
        :loading="sendReqeustLoading"
        :message="confirmModel"
        showInput
        inputLable="Job Title"
        @confirm="confirmSendJobRequest"
        @cancel="cancelSendRequest"
      ></confirm-dialog>
    </v-dialog>
    <v-dialog v-if="showSkillRequestConfirm" v-model="showSkillRequestConfirm" max-width="500" @keydown.esc="showSkillRequestConfirm = false">
      <confirm-dialog
        :loading="sendReqeustLoading"
        :message="confirmModel"
        showInput
        inputLable="Skill"
        @confirm="sendAddSkillRequest"
        @cancel="cancelSendRequest"
      ></confirm-dialog>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapMutations, mapGetters } from "vuex";
import { SHOW_LOADING, HIDDEN_LOADING } from "@/store/types";
import UserInfo from "shared-components/src/models/UserInfo";
//import VImageInput from "vuetify-image-input/a-la-carte";
import CareerHistoryService from "shared-components/src/services/CareerHistoryService";
import CoreSkillService from "@/services/CoreSkillService";
import DetailsService from "shared-components/src/services/DetailsService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import {
  Teammember,
  TeammemberSkill,
  Address,
  Phone,
  LanguageSkill,
  Education,
  Certificate,
  ProfessionalDetail,
} from "shared-components/src/models/Teammember";
import CoreSkill from "shared-components/src/models/CoreSkill";
import { ProfileFields, AlertMessages, ProjectMessages } from "shared-components/src/definitions/constants";
import ContactComponent from "shared-components/src/components/Profile/Contact.vue";
import CareerHistory from "shared-components/src/components/Profile/careerHistory/CareerHistory.vue";
import PersonalDetail from "shared-components/src/components/Profile/PersonalDetail.vue";
import ExperienceDetail from "shared-components/src/components/Profile/ExperienceDetail.vue";
import CertificateComponent from "shared-components/src/components/Profile/certificate/Certificate.vue";
import EducationComponent from "shared-components/src/components/Profile/education/Education.vue";
import LanguageSkillComponent from "shared-components/src/components/Profile/languageSkill/LanguageSkill.vue";
import Skill from "shared-components/src/components/Profile/skill/Skill.vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import Details from "shared-components/src/models/Details";
import { Profile } from "shared-components/src/definitions/config";
import { PersonalDetailModel } from "shared-components/src/models/PersonalDetailModel";
import CareerHistories from "shared-components/src/models/CareerHistories";
import ApiService from "../../services/ApiService";
import store from "@/store";

export default defineComponent({
  props: ["selectedTeammemberModel", "isHiringmanager"],
  data() {
    return {
      tab: null,
      formLoading: false,
      addressSendCodeLoading: false,
      phoneSendCodeLoading: false,
      addressLoading: false,
      phoneNumberLoading: false,
      addresses: [] as Address[],
      addressState: [] as Address[],
      phoneNumbers: [] as Phone[],
      personalDetailModel: {} as PersonalDetailModel,
      professionalDetailModel: {} as ProfessionalDetail,
      addressVerifyLoading: false,
      phoneNumberVerifyLoading: false,
      uploadedCertificate: "",
      isFirstTimeLoad: true,
      submitting: false,
      uploadImage: false,
      fielUpload: false,
      showJobRequestConfirm: false,
      showSkillRequestConfirm: false,
      languages: [] as LanguageSkill[] | null,
      educations: [] as Education[],
      careerHistories: [] as CareerHistories[],
      certificates: [] as Certificate[],
      selectedJob: "",
      selectedSkillTitle: "",
      careerHistoryDescription: "",
      addressEditMode: false,
      phoneEditeMode: false,
      imageData: "",
      teamMember: null as Teammember | null,
      skills: [] as CoreSkill[],
      details: [] as Details[],
      selectedSkill: {} as TeammemberSkill,
      showEditSkillModal: false,
      addSkillLoading: false,
      removeSkillloading: false,
      sendReqeustLoading: false,
      saveRoleAndTitleLoading: false,
      confirmModel: {
        title: "",
        text: "",
      },
      showConfirm: false,
      teammemberSkills: [] as TeammemberSkill[],
      fileRules: [(v: any) => !v || v.size < 2000000 || "Your CV should be less than 2 MB!"],
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      timezones: [] as string[],
      selectedTimeZone: "",
    };
  },
  components: {
    //VImageInput,
    ExperienceDetail,
    LoadingComponent,
    CareerHistory,
    EducationComponent,
    ConfirmDialog,
    ContactComponent,
    Skill,
    CertificateComponent,
    LanguageSkillComponent,
    PersonalDetail,
  },
  computed: {
    ...mapGetters(["teamMemberInfo"]),
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
  },
  methods: {
    async getLanguageSkills() {
      this.formLoading = true;
      var result = await TeamMemberService.getLanguageSkills(this.selectedTeammemberModel.id);
      if (result) {
        this.languages = result;
      }
      this.formLoading = false;
    },
    async getContact() {
      this.formLoading = true;
      var result = await TeamMemberService.getContact(this.selectedTeammemberModel.id);
      debugger;
      if (result) {
        if (result.Addresses) {
          this.addresses = result.Addresses;
        } else {
          this.addresses = [];
        }
        if (result.PhoneNumbers) {
          this.phoneNumbers = result.PhoneNumbers;
        } else {
          this.phoneNumbers = [];
        }
      }
      this.formLoading = false;
    },
    async getProfessionalDetail() {
      this.formLoading = true;
      var result = await TeamMemberService.getExperienceDetail(this.selectedTeammemberModel.id);
      if (result) {
        this.professionalDetailModel = result;
      }
      this.formLoading = false;
    },
    async getEducations() {
      this.formLoading = true;
      var result = await TeamMemberService.getEducations(this.selectedTeammemberModel.id);
      if (result) {
        this.educations = result;
      }
      this.formLoading = false;
    },
    async getCertificates() {
      this.formLoading = true;
      var result = await TeamMemberService.getCertificates(this.selectedTeammemberModel.id);
      if (result) {
        this.certificates = result;
      }
      this.formLoading = false;
    },
    async getCareerHistories() {
      this.formLoading = true;
      var result = await CareerHistoryService.getCareerHistories(this.selectedTeammemberModel.id);
      if (result) {
        this.careerHistories = result;
      }
      this.formLoading = false;
    },
    ...mapMutations([SHOW_LOADING, HIDDEN_LOADING]),
    sendJobRequest(job: any) {
      this.confirmModel.title = "Sending request to add a Job Title";
      this.confirmModel.text = "Are you sure you want to send request to admin to add This?";
      this.showJobRequestConfirm = true;
    },
    cancelSendRequest() {
      this.showJobRequestConfirm = false;
      this.showSkillRequestConfirm = false;
      this.selectedSkillTitle = "";
      this.selectedJob = "";
    },
    async confirmSendJobRequest(job: any) {
      var jobTilteExist = this.details.some((item) => item.Type == "JobTitle" && item.Name.toLowerCase().trim() === job.toLowerCase().trim());
      if (jobTilteExist) {
        this.snackbarMessage({
          message: ProjectMessages.DuplicateJobTitle,
          type: "error",
        });
      } else {
        this.sendReqeustLoading = true;
        var result = await CareerHistoryService.sendAddRequest(job);
        if (result) {
          this.snackbarMessage({
            message: ProjectMessages.JobRequestSuccess,
            type: "success",
          });
        } else {
          this.snackbarMessage({
            message: ProjectMessages.error,
            type: "error",
          });
        }
        this.sendReqeustLoading = false;
        this.showJobRequestConfirm = false;
      }
    },
    showLimitationMessage(text: any) {
      this.snackbarMessage({
        message: text,
        type: "success",
      });
    },
    async verifyPhone(phone: any) {
      this.phoneNumberVerifyLoading = true;
      if (this.teamMember) {
        var phonenumberList = await TeamMemberService.verifyPhone(phone, this.selectedTeammemberModel.id);
        if (phonenumberList && phonenumberList.length > 0) {
          var savedItem = phonenumberList.find((c: any) => c.Id === phone.Id) as Phone;
          var existItem = this.phoneNumbers.find((c) => c.Id === phone.Id);
          if (existItem && existItem.WrongCodeInputCount >= 5) {
            this.snackbarMessage({
              message: ProjectMessages.extraWrongVerifyRequest,
              type: "error",
            });
          } else if (existItem && existItem.WrongCodeInputCount != savedItem.WrongCodeInputCount) {
            this.snackbarMessage({
              message: ProjectMessages.errorVerificationAlert,
              type: "error",
            });
          } else {
            this.snackbarMessage({
              message: ProjectMessages.successPhoneNumberVerificationAlert,
              type: "success",
            });
          }
          this.phoneNumbers = phonenumberList;
        }
      }

      this.phoneNumberVerifyLoading = false;
    },

    async verifyAddress(address: any) {
      this.addressVerifyLoading = true;
      if (this.teamMember) {
        var addresses = await TeamMemberService.verifyAddress(address, this.selectedTeammemberModel.id);
        if (addresses && addresses.length > 0) {
          var savedItem = addresses.find((c: any) => c.Id === address.Id) as Address;
          var existAddress = this.addresses.find((c) => c.Id === address.Id);
          if (existAddress && existAddress.WrongCodeInputCount >= 5) {
            this.snackbarMessage({
              message: ProjectMessages.extraWrongVerifyRequest,
              type: "error",
            });
          } else if (existAddress && existAddress.WrongCodeInputCount != savedItem.WrongCodeInputCount) {
            this.snackbarMessage({
              message: ProjectMessages.errorVerificationAlert,
              type: "error",
            });
          } else {
            this.snackbarMessage({
              message: ProjectMessages.successAddressVerificationAlert,
              type: "success",
            });
          }
          this.addresses = addresses;
        }
      }
      this.addressVerifyLoading = false;
    },

    async sendAddSkillRequest(skill: any) {
      this.sendReqeustLoading = true;
      var skillTilteExist = this.skills.some((item) => item.Text && item.Text.toLowerCase().trim() === skill.toLowerCase().trim());
      if (skillTilteExist) {
        this.snackbarMessage({
          message: ProjectMessages.DuplicateSkillTitle,
          type: "error",
        });
      } else {
        var result = await CoreSkillService.sendAddRequest(skill);
        if (result) {
          this.snackbarMessage({
            message: ProjectMessages.skillRequestSuccess,
            type: "success",
          });
        } else {
          this.snackbarMessage({
            message: ProjectMessages.error,
            type: "error",
          });
        }
      }
      this.sendReqeustLoading = false;
      this.showSkillRequestConfirm = false;
    },
    async sendSkillAddRequest(skillName: any) {
      this.confirmModel.title = `Sending request to add new skill`;
      this.confirmModel.text = `Are you sure you want to send request to admin to add this?`;
      this.showSkillRequestConfirm = true;
      this.selectedSkillTitle = skillName;
    },

    async sendAddressCode(id: any) {
      this.addressSendCodeLoading = true;
      if (this.teamMember) {
        var addresses = await TeamMemberService.sendAddressCode(id, this.selectedTeammemberModel.id);
        this.addressSendCodeLoading = false;
        if (addresses && addresses.length > 0) {
          this.addresses = addresses;
        }
      }
    },
    async sendPhoneCode(id: any) {
      this.phoneSendCodeLoading = true;
      if (this.teamMember) {
        var phoneNumbers = await TeamMemberService.sendPhoneCode(id, this.selectedTeammemberModel.id);
        this.phoneSendCodeLoading = false;
        if (phoneNumbers && phoneNumbers.length > 0) {
          this.phoneNumbers = phoneNumbers;
        }
      }
    },
    async setAddressAsDefault(addressId: any) {
      this.addressLoading = true;
      var addresses = await TeamMemberService.setAddressAsDefault(addressId, this.selectedTeammemberModel.id);
      if (addresses && addresses.length > 0) {
        this.addresses = addresses;
        this.snackbarMessage({
          message: ProjectMessages.addressSaved,
          type: "success",
        });
      } else {
        this.snackbarMessage({
          message: ProjectMessages.error,
          type: "error",
        });
      }
      this.addressLoading = false;
    },
    async setPhoneAsDefault(phoneId: any) {
      this.phoneNumberLoading = true;
      var phonenumbers = await TeamMemberService.setPhoneAsDefault(phoneId, this.selectedTeammemberModel.id);
      if (phonenumbers && phonenumbers.length > 0) {
        this.phoneNumbers = phonenumbers;
        this.snackbarMessage({
          message: ProjectMessages.phoneNumberSaved,
          type: "success",
        });
      } else {
        this.snackbarMessage({
          message: ProjectMessages.error,
          type: "error",
        });
      }
      this.phoneNumberLoading = false;
    },
    async removeAddress(addressId: any) {
      this.addressLoading = true;
      if (this.teamMember) {
        var addresses = await TeamMemberService.removeAddress(addressId, this.selectedTeammemberModel.id);
        if (addresses && addresses.length > 0) {
          this.addresses = addresses;
          this.snackbarMessage({
            message: ProjectMessages.removeAddress,
            type: "success",
          });
        } else {
          this.addresses = [];
        }
      } else {
        this.snackbarMessage({
          message: ProjectMessages.error,
          type: "error",
        });
      }
      this.addressLoading = false;
    },
    async removePhone(phoneId: any) {
      this.phoneNumberLoading = true;
      if (this.teamMember) {
        var phoneNumbers = await TeamMemberService.removePhone(phoneId, this.selectedTeammemberModel.id);
        if (phoneNumbers && phoneNumbers.length > 0) {
          this.phoneNumbers = phoneNumbers;
          this.snackbarMessage({
            message: ProjectMessages.removePhone,
            type: "success",
          });
        } else {
          this.phoneNumbers = [];
        }
      } else {
        this.snackbarMessage({
          message: ProjectMessages.error,
          type: "error",
        });
      }
      this.phoneNumberLoading = false;
    },

    async savePhone(phoneNumber: any) {
      this.phoneNumberLoading = true;
      if (this.teamMember) {
        var phoneNumbers = await TeamMemberService.savePhone(phoneNumber, this.selectedTeammemberModel.id);
        if (phoneNumbers && phoneNumbers.length > 0) {
          this.phoneNumbers = phoneNumbers;
          this.snackbarMessage({
            message: ProjectMessages.savePhoneNumber,
            type: "success",
          });
        }
      } else {
        this.snackbarMessage({
          message: ProjectMessages.error,
          type: "error",
        });
      }
      this.phoneNumberLoading = false;
    },
    async saveRoleAndTitle(details: any) {
      if (this.teamMember) {
        this.saveRoleAndTitleLoading = true;
        var result = await TeamMemberService.saveRoleAndTitle(details, this.selectedTeammemberModel.email);
        if (result) {
          this.setTeamMemberInfo(this.teamMember);
          var messageObject = {
            message: AlertMessages.SuccessProfileUpdate,
            type: "success",
            timeOut: Profile.ErrorSetting.SnackbarTimeout,
          };
          this.snackbarMessage(messageObject);
        } else {
          var messageObject = {
            message: AlertMessages.ValidationErrorProfileUpdate,
            type: "error",
            timeOut: Profile.ErrorSetting.SnackbarTimeout,
          };
          this.snackbarMessage(messageObject);
        }
        this.saveRoleAndTitleLoading = false;
      }
    },
    async saveAddress(address: Address) {
      this.addressLoading = true;
      if (this.teamMember) {
        var addressList = [...this.addresses];
        var addressList = await TeamMemberService.saveAddress(address, this.selectedTeammemberModel.id);
        if (addressList && addressList.length > 0) {
          this.addresses = addressList;
          this.snackbarMessage({
            message: ProjectMessages.saveAddress,
            type: "success",
          });
        }
      } else {
        this.snackbarMessage({
          message: ProjectMessages.error,
          type: "error",
        });
      }
      this.addressLoading = false;
    },
    async skillDownload(certificateUrl: any) {
      if (this.teamMember) {
        const downloadLink = await TeamMemberService.getSkillDownloadLink(certificateUrl, this.selectedTeammemberModel.id);
        window.open(downloadLink, "_blank");
      }
    },
    async cvDownload() {
      if (this.teamMember) {
        const downloadLink = await TeamMemberService.getResumeDownloadLink(this.selectedTeammemberModel.id);
        window.open(downloadLink, "_blank");
      }
    },
    async skillCertificateChanged(skill: any) {
      if (this.teamMember) {
        this.uploadedCertificate = await TeamMemberService.uploadSkillCv(skill.skillId, skill.file, this.selectedTeammemberModel.id);
      }
    },
    async fileChanged(file: File) {
      if (this.teamMember) {
        // this.teamMember.CV = await TeamMemberService.uploadResume(file,this.selectedTeammemberModel.id);
      }
    },
    setUserInfo(userInfo: UserInfo) {
      return store.dispatch("setUserInfo", { userInfo, vm: this });
    },
    setCareerHistoryDescriptionState(careerHistoryDescription: any) {
      if (this.teamMember) {
        this.teamMember.CareerHistoryDescription = careerHistoryDescription;
      }
    },
    async fetchTeammemberInfo() {
      try {
        if (!this.userInfo || !this.userInfo.id) {
          throw Error("Please login!");
        } else {
          this.teamMember = await TeamMemberService.getPersonalDetail(this.selectedTeammemberModel.id);
        }
      } catch (teammemberError) {
        this.snackbarMessage({
          message: "There is some error to get your team member inforamtion!",
          type: "error",
        });
      }
      if (this.teamMember) {
        if (!this.teamMember.FirstName) {
          this.teamMember.FirstName = this.userInfo.name;
        }
        if (!this.teamMember.LastName) {
          this.teamMember.LastName = this.userInfo.surname;
        }
        if (!this.teamMember.Phone) {
          this.teamMember.Phone = this.userInfo.phoneNumber;
        }
        if (!this.teamMember.Email) {
          this.teamMember.Email = this.userInfo.userName || "";
        }
        if (!this.teamMember.MemberSince) {
          this.teamMember.MemberSince = this.userInfo.creationDate;
        }
        this.selectedTimeZone = this.teamMember.Timezone;
        this.teammemberSkills = this.teamMember.Skills ? this.teamMember.Skills : [];
        if (!this.teamMember.SecondaryEmail) {
          this.teamMember.SecondaryEmail = this.teamMember.Email;
        }
        var personalDetailModel = {
          Id: this.teamMember.Id,
          BirthDate: this.teamMember.BirthDate,
          Country: this.teamMember.Country,
          City: this.teamMember.City,
          CV: this.teamMember.CV,
          Description: this.teamMember.Description,
          Email: this.teamMember.Email,
          FirstName: this.teamMember.FirstName,
          GitHub: this.teamMember.GitHub,
          LastName: this.teamMember.LastName,
          LinkedIn: this.teamMember.LinkedIn,
          MaritalStatus: this.teamMember.MaritalStatus,
          MilitaryServiceSituation: this.teamMember.MilitaryServiceSituation,
          Mobile: this.teamMember.Mobile,
          Nationality: this.teamMember.Nationality,
          NickName: this.teamMember.NickName,
          Phone: this.teamMember.Phone,
          PhotoUrl: this.teamMember.PhotoUrl,
          SecondaryEmail: this.teamMember.SecondaryEmail,
          StillStudying: this.teamMember.StillStudying,
        } as PersonalDetailModel;
        this.personalDetailModel = personalDetailModel;
        this.careerHistoryDescription = this.teamMember.CareerHistoryDescription;
      }
    },
    async remove(item: TeammemberSkill) {
      var findSkill = this.skills.find((c) => c.id === item.SkillId);
      if (findSkill) {
        this.confirmModel.title = `Remove Skill(${findSkill?.Text})`;
        this.confirmModel.text = "Are you sure?";
        this.showConfirm = true;
        this.selectedSkill = item;
      }
    },
    async confirmRemove(): Promise<void> {
      this.showConfirm = false;
      this.removeSkillloading = true;
      if (this.selectedSkill) {
        const idx = this.teammemberSkills.findIndex((x) => x.SkillId === (this.selectedSkill ? this.selectedSkill.SkillId : ""));
        const result = await TeamMemberService.removeSkill(this.selectedSkill.SkillId, this.selectedTeammemberModel.id);
        if (result) {
          this.teammemberSkills = result;
          if (this.teamMember) {
            this.teamMember.Skills = this.teammemberSkills;
            this.setTeamMemberInfo(this.teamMember);
          }
          this.snackbarMessage({
            message: ProjectMessages.succesfullyRemoveSkill,
            type: "success",
          });

        } else {
          this.snackbarMessage({
            message: ProjectMessages.errorRemoveSkill,
            type: "error",
          });
        }
      }
      this.removeSkillloading = false;
    },
    cancelRemove(): void {
      this.showConfirm = false;
      this.selectedSkill = {} as TeammemberSkill;
    },
    async loadSkillsList() {
      this.skills = await CoreSkillService.getList();
    },
    async loadDetailsList() {
      this.details = await DetailsService.getDetails(ApiService);
    },
    async saveSkill(skillModel: TeammemberSkill) {
      this.addSkillLoading = true;
      var findSkill = this.skills.find((c) => c.id === skillModel.SkillId || c.Text.trim().toLowerCase() === skillModel.SkillId.trim().toLowerCase());
      if (findSkill) {
        skillModel.SkillId = skillModel.SkillId;
      }
      var result = await TeamMemberService.updateSkill(skillModel, this.selectedTeammemberModel.email);
      if (result && result.length > 0) {
        if (!findSkill) {
          var skillData = [...this.skills];
          var mappedSkills = this.skills.map((c) => c.id);
          var savedSkill = result.find((c: any) => !mappedSkills.includes(c.SkillId));
          if (savedSkill) {
            skillData.push({ id: savedSkill.SkillId, Text: skillModel.SkillId } as CoreSkill);
            this.skills = skillData;
          }
        }
        this.teammemberSkills = result;
        this.snackbarMessage({
          message: ProjectMessages.succesfullyAddSkill,
          type: "success",
        });
      } else {
        this.snackbarMessage({
          message: ProjectMessages.errorAddSkill,
          type: "error",
        });
      }
      this.addSkillLoading = false;
      this.closeEditModal();
    },
    closeEditModal() {
      this.showEditSkillModal = false;
    },
    showEditSkill() {
      this.showEditSkillModal = true;
    },
    setTeamMemberInfo(teamMemberInfo: Teammember) {
      return store.dispatch("setTeamMemberInfo", { teamMemberInfo, vm: this });
    },
    snackbarMessage(messageObject: any) {
      if(messageObject.type == "success"){
        store.dispatch("showSuccessMessage", messageObject.message)
      }else {
        store.dispatch("showErrorMessage", messageObject.message)
      }
    },
    async submitExperienceCareerObjectiveDetail(event: { model: any; callbackMethod: any }) {
      this.submitting = true;
      var result = await TeamMemberService.saveExperienceCareerObjectiveDetail(event.model, this.selectedTeammemberModel.id);
      if (result != null && result.Id) {
        if (this.teamMember) {
          if (!this.professionalDetailModel) {
            this.professionalDetailModel = {} as ProfessionalDetail;
          }
          if (!this.professionalDetailModel.CareerObjectives) {
            this.professionalDetailModel.CareerObjectives = [];
          }

          const existingItemIndex = this.professionalDetailModel.CareerObjectives.findIndex((item: any) => item.Id == (result as any).Id);
          if (existingItemIndex == -1) {
            this.professionalDetailModel.CareerObjectives.push(result);
          } else {
            this.professionalDetailModel.CareerObjectives[existingItemIndex] = result
          }
          this.teamMember.ProfessionalDetail = this.professionalDetailModel;

          event.callbackMethod();
        }
        this.snackbarMessage({
          message: ProjectMessages.succesfullyAddExperienceCareerObjectiveDetail,
          type: "success",
        });
      } else if (result == null) {
        this.snackbarMessage({
          message: ProjectMessages.errorAddExperienceCareerObjectiveDetail,
          type: "error",
        });
      } else {
        this.snackbarMessage({
          message: result,
          type: "error",
        });
      }
      this.submitting = false;
    },
    async RemoveCareerObjective(event: { id: any; callbackMethod: any }) {
      this.submitting = true;
      var result = await TeamMemberService.removeExperienceCareerObjectiveDetail(event.id, this.selectedTeammemberModel.id);
      if (result) {
        if (this.teamMember && this.professionalDetailModel) {
          const index = this.professionalDetailModel.CareerObjectives.findIndex((item: any) => item.Id == event.id);
          if (index != -1) {
            this.professionalDetailModel.CareerObjectives.splice(index, 1);
          }
          this.teamMember.ProfessionalDetail = this.professionalDetailModel;
          event.callbackMethod();
        }
        this.snackbarMessage({
          message: ProjectMessages.succesfullyRemoveExperienceCareerObjectiveDetail,
          type: "success",
        });
      } else {
        this.snackbarMessage({
          message: ProjectMessages.errorRemoveExperienceCareerObjectiveDetail,
          type: "error",
        });
      }
      this.submitting = false;
    },
    updateJobTitle(item: Details) {
      var detailList = [...this.details];
      detailList.push(item);
      this.details = detailList;
    },
    async submitExperienceDetail(model: any) {
      this.submitting = true;
      if (model.tm.Job) {
        var jobtitle = this.details.find(
          (c) => c.Type == "JobTitle" && (c.id === model.tm.Job || (c.Name && c.Name.trim().toLowerCase() === model.tm.Job.trim().toLowerCase()))
        );
        if (jobtitle) {
          model.tm.Job = jobtitle.id;
        }
      }
      var result = await TeamMemberService.saveExperienceDetail(model.tm, this.selectedTeammemberModel.id);
      if (result) {
        if (this.teamMember) {
          this.teamMember.ProfessionalDetail = model.tm;
          this.teamMember.SpecificRoles = model.tm.JobRole;
          if (!jobtitle) {
            this.updateJobTitle({ id: result.Job, Name: model.tm.Job, RoleId: model.tm.JobRole, Type: "JobTitle" } as Details);
          }
        }
        this.snackbarMessage({
          message: ProjectMessages.succesfullyAddExperienceDetail,
          type: "success",
        });
      } else {
        this.snackbarMessage({
          message: ProjectMessages.errorAddExperienceDetail,
          type: "error",
        });
      }
      this.submitting = false;
    },
    async scrollToElement(el: any) {
      if (el) {
        el.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    },
    async submitPersonalDetail(model: any) {
      this.submitting = true;
      var result = await TeamMemberService.savePersonalDetail(model.tm, this.selectedTeammemberModel.id);
      if (result && result.Id) {
        this.personalDetailModel = result;
        if (this.teamMember) {
          this.teamMember.BirthDate = result.BirthDate;
          this.teamMember.Country = result.Country;
          this.teamMember.City = result.City;
          this.teamMember.CV = result.CV;
          this.teamMember.Description = result.Description;
          this.teamMember.Email = result.Email;
          this.teamMember.FirstName = result.FirstName;
          this.teamMember.GitHub = result.GitHub;
          this.teamMember.LastName = result.LastName;
          this.teamMember.LinkedIn = result.LinkedIn;
          this.teamMember.MaritalStatus = result.MaritalStatus;
          this.teamMember.MilitaryServiceSituation = result.MilitaryServiceSituation;
          this.teamMember.Mobile = result.Mobile;
          this.teamMember.Nationality = result.Nationality;
          this.teamMember.NickName = result.NickName;
          this.teamMember.Phone = result.Phone;
          this.teamMember.PhotoUrl = result.PhotoUrl;
          this.teamMember.SecondaryEmail = result.SecondaryEmail;
          this.teamMember.StillStudying = result.StillStudying;
        }
        this.snackbarMessage({
          message: ProjectMessages.succesfullyAddPersonalDetails,
          type: "success",
        });
      } else {
        this.snackbarMessage({
          message: ProjectMessages.errorAddPersonalDetails,
          type: "success",
        });
      }
      this.submitting = false;
    },
    setImage() {
      if (this.teamMember) {
        this.teamMember.PhotoUrl = this.imageData;
        this.uploadImage = false;
      }
    },
  },
  watch: {
    selectedTimeZone(newVal) {
      if (this.teamMember) {
        this.teamMember.Timezone = newVal;
      }
    },
  },
  created() {
    this.SHOW_LOADING();
  },
  async mounted() {
    this.timezones = ProfileFields.Timezones;
    await this.fetchTeammemberInfo();
    await this.loadSkillsList();
    await this.loadDetailsList();
    this.isFirstTimeLoad = false;
    this.HIDDEN_LOADING();
  },
});
</script>

<style scoped lang="scss">
@import "node_modules/shared-components/assets/colors.scss";
@import "node_modules/shared-components/assets/sizes.scss";

.v-tabs-items {
  background-color: transparent;
}

a.v-tab--active {
  background-color: #2e2e2e;
}

.profile-actionbar {
  position: sticky;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 45px;
    width: 460px;
    min-width: 25%;
    max-width: 80%;
  }
}

.title {
  color: #dc1e28;
  font-weight: 700;
  font-size: 25px !important;
}

.checkbox-title {
  display: flex;
  align-items: center;
}

.user-name {
  font-size: 22px;
  line-height: 28px;
  font-weight: 600;
}

.email {
  margin-top: 10px;
}

.image-container {
  width: fit-content;
  margin: 0 auto;
  position: relative;
  border-radius: 5px;
  overflow: hidden;

  &.has-image {
    border-radius: 50%;
  }

  &:hover {
    .upload-button {
      opacity: 1;
    }
  }

  .profile-image {
    width: 220px;
    height: 220px;
    font-size: 220px;
    border-radius: 6px;
  }

  .upload-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 59px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 19px;
    justify-content: center;
    font-weight: 700;
    border-radius: 0 0 5px 5px;
    cursor: pointer;
    transition: all 0.2s;
    opacity: 0;
  }
}
</style>
