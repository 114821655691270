import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contribution_report = _resolveComponent("contribution-report", true)!

  return (_openBlock(), _createBlock(_component_contribution_report, {
    ref: "cntbnReport",
    customers: _ctx.customers,
    projects: _ctx.projects,
    authors: _ctx.authors,
    commits: _ctx.commits,
    timesheets: _ctx.timesheets,
    onCreateReport: _ctx.createReport,
    onCustomerSelected: _ctx.customerSelected
  }, null, 8, ["customers", "projects", "authors", "commits", "timesheets", "onCreateReport", "onCustomerSelected"]))
}