<template>
  <v-card>
    <v-card-title>
      Time Sheet List
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn v-if="useAsModal" class="error mt-1 mb-1" fab x-small title="Close" @click="closeModal">X</v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" sm="12">
          <v-row>
            <v-col class="filters" md="4">
              <AutoCompleteField
                :loading="fillTeammembersLoading"
                v-model="selectedTeammember"
                :items="teammembers"
                item-title="FullName"
                item-value="Id"
                outlined
                dense
                label="Team Member"
                return-object
              >
                <template v-slot:item="{ item, index, props }">
                  <v-list>
                    <v-list-item v-bind="props" :class="{ 'txt-color': item.raw.isFirstInactive }" :title="item.raw.FullName"></v-list-item>
                  </v-list>
                </template>
              </AutoCompleteField>
            </v-col>
            <v-col class="filters" md="4" v-if="selectedTeammember">
              <AutoCompleteField
                :loading="fillCommitmentsLoading"
                v-model="selectedCommitment"
                :items="commitments"
                item-title="Name"
                item-value="Id"
                outlined
                dense
                label="Commitment"
                return-object
              >
                <template v-slot:item="{ item, index, props }">
                  <v-list>
                    <v-list-item v-bind="props" :class="{ 'txt-color': isFirstInactive(item.raw.id) }" :title="item.raw.Name"></v-list-item>
                  </v-list>
                </template>
              </AutoCompleteField>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="mx-4" inset horizontal></v-divider>
      <Timesheet
        v-if="selectedTeammember && shouldRender"
        :isAdmin="!useForCustomer"
        :isCustomer="useForCustomer"
        :timesheetsVersion="timesheetsVersion"
        :showRegister="showRegister"
        :dateOffset="dateOffset"
        :userProjects="userProjects"
        :selectedTeammember="selectedTeammember"
        :selectedCommitment="selectedCommitment"
        :fetchAllCommitmentsService="fetchAllCommitments"
        :fetchTimeSheetsService="fetchTimesheets"
        :fetchLeavesService="fetchLeaves"
        :finishedCloneProcess="finishedCloneProcess"
        :cloneTimesheetId="cloneTimesheetId"
        :regLoading="regLoading"
        @registerTimesheet="registerTimesheet"
        @confirmTimesheet="confirmTimesheet"
        @cancelRegister="cancel"
        @errorRaised="errorRaised"
        @confirmClone="confirmClone"
        @deleteLeave="deleteLeave"
        @deleteTimesheet="deleteTimesheet"
        @confirmLeave="confirmLeave"
      ></Timesheet>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Teammember, TeammemberWithSummaryDetails } from "shared-components/src/models/Teammember";
import Timesheet from "shared-components/src/components/Timesheet/List.vue";
import AuditTrailService from "shared-components/src/services/AuditTrailService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import LeaveService from "shared-components/src/services/LeaveService";
import ProjectService from "shared-components/src/services/ProjectService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import TimesheetService from "shared-components/src/services/TimesheetService";
import Utils from "shared-components/src/utils/Utils";
import TimesheetModel from "shared-components/src/models/Timesheet";
import Project from "shared-components/src/models/Project";
import Leave from "shared-components/src/models/Leave";
import UICommitment from "shared-components/src/models/Commitment";
import Commitment from "shared-components/src/models/Commitment";
import { AuditTrailRecordTypes } from "shared-components/src/definitions/constants";
import AuditTrail from "shared-components/src/models/AuditTrail";
import UserInfo from "shared-components/src/models/UserInfo";
import AppHelper from "shared-components/src/utils/AppHelper";
import store from "@/store";

export default defineComponent({
  components: {
    Timesheet,
  },
  props: {
    importedTeammemberId: {
      type: String,
      default: "",
    },
    importedCommitmentId: {
      type: String,
      default: "",
    },
    useAsModal: {
      type: Boolean,
      default: false,
    },
    useForCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userProjects: [] as Project[],
      cloneTimesheetId: "",
      registerDate: "",
      regLoading: false,
      showRegister: false,
      shouldRender: false,
      fillTeammembersLoading: true,
      fillCommitmentsLoading: true,
      teammembers: [] as Teammember[] | TeammemberWithSummaryDetails[],
      commitments: [] as Commitment[],
      selectedTeammember: undefined as Teammember | TeammemberWithSummaryDetails | undefined,
      selectedCommitment: undefined as Commitment | undefined,
      inactiveCommitments: undefined as Commitment[] | undefined,
      timesheetsVersion: 0,
      loadingPage: true,
      finishedCloneProcess: false,
    };
  },
  async mounted() {
    await this.fillTeammembers();
  },
  methods: {
    async fetchUserProjects() {
      if (this.selectedTeammember && this.selectedTeammember.Id) {
        this.userProjects = await ProjectService.getByTeammemberId(this.selectedTeammember.Id);
      }
    },
    async cancel() {
      this.showRegister = false;
    },
    isFirstInactive(commitmentId: any) {
      return this.inactiveCommitments && this.inactiveCommitments[0] && this.inactiveCommitments[0]?.id === commitmentId;
    },
    sortList(commitments: any) {
      return commitments.sort((a: any, b: any) => (a.Name.toLowerCase() > b.Name.toLowerCase() ? 1 : -1));
    },
    closeModal() {
      this.$emit("closeModal");
    },
    loadList() {
      this.timesheetsVersion++;
    },
    async fillTeammembers() {
      try {
        this.fillTeammembersLoading = true;
        if (this.importedTeammemberId) {
          TeamMemberService.getSortedSummaryList()
            .then((items: any) => {
              const item = items.find((x: any) => x.Id === this.importedTeammemberId);
              if (item) {
                this.teammembers = [item];
                this.selectedTeammember = item;
              }
              this.fillTeammembersLoading = false;
            })
            .catch((err: any) => this.$emit("errorRaised", err));
        } else {
          if (AppHelper.IsAzureEnv()) {
            TeamMemberService.getSortedSummaryList()
              .then((items: any) => {
                this.teammembers = items;
                var defaultTeammemberId = this.$route.params.tmId;
                if (defaultTeammemberId && this.teammembers) {
                  this.selectedTeammember = this.teammembers.find((x) => x.Id == defaultTeammemberId);
                }
                this.fillTeammembersLoading = false;
              })
              .catch((err: any) => this.$emit("errorRaised", err));
          } else {
            TeamMemberService.getSortedList()
              .then((items: any) => {
                this.teammembers = items;
                var defaultTeammemberId = this.$route.params.tmId;
                if (defaultTeammemberId && this.teammembers) {
                  this.selectedTeammember = this.teammembers.find((x) => x.Id == defaultTeammemberId);
                }
                this.fillTeammembersLoading = false;
              })
              .catch((err: any) => this.$emit("errorRaised", err));
          }
        }
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    async fillCommitments() {
      try {
        this.fillCommitmentsLoading = true;

        if (this.importedCommitmentId) {
          const importedCommitment = await CommitmentService.get(this.importedCommitmentId);
          if (importedCommitment) {
            this.selectedCommitment = importedCommitment;
          }
        }

        this.fetchAllCommitments()
          .then((items) => {
            var nowDate = new Date();
            var activeCommitments = items.filter(
              (c) => c.Name && c.IsActive && c.StartDate && c.StartDate < nowDate && (!c.EndDate || (c.EndDate && c.EndDate > nowDate))
            );
            var inactiveCommitments = items.filter((c) => c.Name && !activeCommitments.some((a) => a.id === c.id));
            var sortedActiveCommitments = this.sortList(activeCommitments);
            this.inactiveCommitments = this.sortList(inactiveCommitments);
            this.commitments = activeCommitments;
            this.commitments.push(...inactiveCommitments);
            if (this.commitments && this.commitments.length > 1) {
              var commitmentAllObject = { id: "", Name: "All" } as Commitment;
              this.commitments.splice(0, 0, commitmentAllObject);
              this.selectedCommitment = commitmentAllObject;
            }
            this.fillCommitmentsLoading = false;
          })
          .catch((err) => this.$emit("errorRaised", err));
      } catch (ex) {
        this.$emit("errorRaised", ex);
      }
    },
    async confirmTimesheet(saveModel: any): Promise<void> {
      var timesheet = saveModel.timesheet;
      this.regLoading = true;
      TimesheetService.saveTimesheet(timesheet)
        .then((result: any) => {
          const audit = {
            RecordType: {
              Type: AuditTrailRecordTypes.Timesheet,
              Id: result,
            },
            Event: timesheet.id ? "Update" : "Create",
            DateTime: new Date(),
            UserId: this.userInfo.id,
          } as AuditTrail;
          AuditTrailService.setForAdmin(audit);
          if (timesheet.Date) {
            timesheet.Date = Utils.addTimezoneOffset(timesheet.Date);
          }
          if (!saveModel.addAfterSave) {
            this.cancel();
          }
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
          console.error(error);
        })
        .finally(() => {
          this.loadList();
          this.regLoading = false;
        });
    },
    errorRaised(msg: string): void {
      store.dispatch("showErrorMessage", msg);
    },
    async registerTimesheet(): Promise<void> {
      if (this.selectedTeammember) {
        this.showRegister = true;
      } else {
        this.errorRaised("Please select a team member from the list!");
      }
    },
    async deleteTimesheet(id: string): Promise<void> {
      await TimesheetService.delete(id)
        .then(() => {
          this.loadList();
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
          console.error(error);
        });
    },
    async deleteLeave(id: string): Promise<void> {
      await LeaveService.delete(id)
        .then(() => {
          this.loadList();
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
          console.error(error);
        });
    },
    async fetchAllCommitments(): Promise<UICommitment[]> {
      return this.selectedCommitmentId && this.selectedCommitment
        ? [this.selectedCommitment]
        : await CommitmentService.getCommitmentsByTeammeberId(this.selectedTeammemberId);
    },
    async fetchTimesheets(model: any) {
      let timesheets = await TimesheetService.getListInDateRangeByTeammemberAndCommitment(
        this.selectedTeammemberId,
        this.selectedCommitmentId,
        model.startDate,
        model.endDate
      );
      timesheets = timesheets.filter((c: any) => c.Date && c.Date >= model.startDate && c.Date <= model.endDate);
      return timesheets;
    },
    async fetchLeaves(model: any) {
      return await LeaveService.getListInDateRange(this.selectedTeammemberId, model.startDate, model.endDate);
    },
    confirmClone(model: any) {
      var timesheet = model.timesheet as TimesheetModel;
      this.finishedCloneProcess = false;
      timesheet.Date = Utils.addTimezoneOffset(timesheet.Date);
      if (model.goToEdit === true) {
        this.finishedCloneProcess = true;
        this.registerTimesheet();
      } else {
        TimesheetService.saveTimesheet(timesheet)
          .then()
          .catch((error: any) => {
            if (error.response && error.response.status === 400) {
              this.errorRaised(error.response.data);
            }
            this.errorRaised(error.response.data);
          })
          .finally(() => {
            this.finishedCloneProcess = true;
          });
      }
    },
    async confirmLeave(leave: Leave) {
      LeaveService.set(leave)
        .then((data: any) => {
          this.loadList();
        })
        .catch((error: any) => {
          if (error.response && error.response.status === 400) {
            this.errorRaised(error.response.data);
          } else {
            this.errorRaised("There is an error to submit the timesheet record!");
          }
          console.error(error);
        });
    },
  },
  watch: {
    selectedTeammember(newVal) {
      this.shouldRender = false;
      this.selectedCommitment = undefined;
      this.$nextTick(() => {
        this.shouldRender = true;
      });
      this.fetchUserProjects();
      // fetch commitments
      this.fillCommitments();
    },
    selectedCommitment(oldVal, newVal) {
      if (typeof newVal !== "undefined" && typeof oldVal !== "undefined" && oldVal!.id !== newVal!.id) {
        this.shouldRender = false;
        this.$nextTick(() => {
          this.shouldRender = true;
        });
      }

      //this.shouldRender = false;
      // this.$nextTick(() => {
      //   this.shouldRender = true;
      // });
    },
  },
  computed: {
    userInfo(): UserInfo {
      return store.state.userInfo;
    },
    selectedTeammemberId(): string {
      return this.selectedTeammember ? this.selectedTeammember.Id : "";
    },
    selectedCommitmentId(): string {
      return this.selectedCommitment ? this.selectedCommitment.id : "";
    },
    dateOffset(): number | undefined {
      if (this.$route.params.dateOffset) {
        return parseInt(this.$route.params.dateOffset as string, 10);
      } else {
        return undefined;
      }
    },
  },
});
</script>
<style lang="scss">
@import "node_modules/shared-components/assets/style/color.scss";
.fc-col-header-cell {
  a {
    color: $c_black !important;
  }
}
</style>
