import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "close-profile-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_Information = _resolveComponent("Information")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_loading_component = _resolveComponent("loading-component")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, null, {
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createTextVNode(" Search Team members ")),
            _createVNode(_component_v_divider, {
              class: "mx-4",
              inset: "",
              vertical: ""
            }),
            _createVNode(_component_v_spacer)
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, { class: "pr-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_divider),
            _createVNode(_component_v_row, { class: "mt-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "nameField pr-8",
                  cols: "12",
                  md: "6",
                  xs: "12",
                  sm: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      dense: "",
                      outlined: "",
                      class: "mt-md-3",
                      label: "Teammember",
                      type: "text",
                      "hide-details": "",
                      modelValue: _ctx.teammemberName,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.teammemberName) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6",
                  xs: "12",
                  sm: "6",
                  class: "pr-6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      dense: "",
                      outlined: "",
                      class: "mt-md-3",
                      label: "Job Title",
                      "hide-details": "",
                      type: "text"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  class: "searchFields pr-8",
                  cols: "12",
                  md: "6",
                  xs: "12",
                  sm: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_combobox, {
                      "hide-details": "",
                      class: "mt-md-3 mb-md-8",
                      label: "Skills",
                      items: _ctx.skills,
                      modelValue: _ctx.selectedSkills,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSkills) = $event)),
                      "item-title": "Text",
                      "item-value": "id",
                      multiple: "",
                      chips: "",
                      outlined: "",
                      dense: "",
                      loading: _ctx.skillLoading
                    }, {
                      selection: _withCtx((data) => [
                        (_openBlock(), _createBlock(_component_v_chip, _mergeProps({
                          key: JSON.stringify(data.item)
                        }, data.attrs, {
                          "input-value": data.selected,
                          disabled: data.disabled,
                          "onClick:close": ($event: any) => (data.parent.selectItem(data.item))
                        }), {
                          default: _withCtx(() => [
                            _createVNode(_component_v_avatar, {
                              class: "accent text_white",
                              left: "",
                              textContent: _toDisplayString(data.item.Text.slice(0, 1).toUpperCase())
                            }, null, 8, ["textContent"]),
                            _createTextVNode(" " + _toDisplayString(data.item.Text), 1)
                          ]),
                          _: 2
                        }, 1040, ["input-value", "disabled", "onClick:close"]))
                      ]),
                      _: 1
                    }, 8, ["items", "modelValue", "loading"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "6",
                  xs: "12",
                  sm: "6",
                  class: "pr-6"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_text_field, {
                      "hide-details": "",
                      dense: "",
                      outlined: "",
                      class: "mt-md-3",
                      label: "Total experience",
                      type: "text"
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_data_table, {
                      class: "pr-5",
                      headers: _ctx.headers,
                      items: _ctx.tableData,
                      options: _ctx.tableOptions
                    }, {
                      "item.JobTitle": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(item.SpecificTitle), 1)
                      ]),
                      "item.Skills": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.getSkillsNames(item)), 1)
                      ]),
                      "item.TotalExperience": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(item.ProfessionalDetail?.ExperienceYear), 1)
                      ]),
                      "item.actions": _withCtx(({ item }) => [
                        _createVNode(_component_v_icon, {
                          small: "",
                          class: "mr-20",
                          onClick: ($event: any) => (_ctx.getTeammember(item.Id))
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("mdi-pencil")
                          ])),
                          _: 2
                        }, 1032, ["onClick"]),
                        _createVNode(_component_v_icon, {
                          small: "",
                          class: "mr-20"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("mdi-account")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["headers", "items", "options"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.profileDialog)
              ? (_openBlock(), _createBlock(_component_v_dialog, {
                  key: 0,
                  modelValue: _ctx.profileDialog,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.profileDialog) = $event)),
                  fullscreen: "",
                  "hide-overlay": "",
                  transition: "dialog-bottom-transiton"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_v_btn, {
                            elevation: "2",
                            icon: "",
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.profileDialog = false))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_icon, null, {
                                default: _withCtx(() => _cache[7] || (_cache[7] = [
                                  _createTextVNode("mdi-close")
                                ])),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _createVNode(_component_Information, {
                          selectedTeammemberModel: _ctx.selectedTeammemberModel,
                          isHiringmanager: _ctx.isHiringmanager
                        }, null, 8, ["selectedTeammemberModel", "isHiringmanager"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 512), [
      [_vShow, !_ctx.pageLoading]
    ]),
    (_ctx.pageLoading)
      ? (_openBlock(), _createBlock(_component_loading_component, { key: 0 }))
      : _createCommentVNode("", true)
  ]))
}