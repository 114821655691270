import Notification from "shared-components/src/models/Notification";
import ApiService from "./ApiService";
import Utils from "shared-components/src/utils/Utils";
import NotificationItem from "shared-components/src/models/NotificationItem";
import { NotificationModel, SendCommitmentNotificationRequestModel } from "shared-components/src/services/openApi";

export default class NotificationService {
  public static async getById(id: string): Promise<Notification> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification/${id}`, "")
        .then((result) => {
          if (!result.data) {
            resolve({} as Notification);
          } else {
            const item = result.data as Notification;
            resolve(item);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async GetByNotificationId(notificationId: string): Promise<NotificationItem[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification/items/${notificationId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              if (doc.SeenDate) {
                item.SeenDate = Utils.vsDateToDatetime(doc.SeenDate);
              }
              const retVal = { ...item } as NotificationItem;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getList(): Promise<Notification[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            const items = result.data.map((doc: any) => {
              const item = doc;
              if (doc.CreationDate) {
                item.CreationDate = Utils.vsDateToDatetime(doc.CreationDate);
              }
              if (doc.SeenDate) {
                item.SeenDate = Utils.vsDateToDatetime(doc.SeenDate);
              }
              if (doc.Schedule) {
                item.Schedule = Utils.vsDateToDatetime(doc.Schedule);
              }
              const retVal = { ...item } as Notification;
              return retVal;
            });
            resolve(items);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async getByCommitmentId(commitmentId: string): Promise<NotificationModel[]> {
    return new Promise((resolve, reject) => {
      ApiService.get(`/notification/getByCommitmentId/${commitmentId}`, "")
        .then((result) => {
          if (!result.data) {
            resolve([]);
          } else {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async set(model: any): Promise<Notification> {
    return new Promise((resolve, reject) => {
      ApiService.post("/notification", model)
        .then((result) => {
          if (result.data) {
            model.id = result.data;
            resolve(model);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async sendCommitmentNotification(commitmentId: string, model: SendCommitmentNotificationRequestModel): Promise<NotificationModel> {
    return new Promise((resolve, reject) => {
      ApiService.post(`/commitment/${commitmentId}/sendNotification`, model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }

  public static async update(model: Notification): Promise<string> {
    return new Promise((resolve, reject) => {
      ApiService.put("/notification", model)
        .then((result) => {
          if (result.data) {
            resolve(result.data);
          }
        })
        .catch((addErr) => {
          reject(addErr);
        });
    });
  }
}
