import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_date_picker = _resolveComponent("v-date-picker")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_combobox = _resolveComponent("v-combobox")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[9] || (_cache[9] = [
          _createTextVNode(" Add new assessment ")
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_form, {
            ref: "frmAssessment",
            modelValue: _ctx.valid,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.valid) = $event)),
            "lazy-validation": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_menu, {
                        "close-on-content-click": true,
                        modelValue: _ctx.datePickerMenu,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.datePickerMenu) = $event)),
                        "offset-y": ""
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_text_field, _mergeProps({
                            dense: "",
                            rules: [_ctx.rules.required],
                            modelValue: _ctx.selectedDate,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedDate) = $event)),
                            readonly: ""
                          }, props, {
                            label: "Start date",
                            outlined: ""
                          }), null, 16, ["rules", "modelValue"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_card, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_card_text, { class: "text-center" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_date_picker, {
                                    "first-day-of-week": "1",
                                    modelValue: _ctx.selectedDate,
                                    "onUpdate:modelValue": [
                                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedDate) = $event)),
                                      _cache[2] || (_cache[2] = ($event: any) => (_ctx.datePickerMenu = false))
                                    ]
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_combobox, {
                        dense: "",
                        rules: [_ctx.rules.required],
                        label: "Platform",
                        modelValue: _ctx.model.Platform,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.Platform) = $event)),
                        items: ['Coderbyte', 'TestDome'],
                        multiple: "",
                        outlined: "",
                        chips: ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_combobox, {
                        dense: "",
                        rules: [_ctx.rules.required],
                        label: "Core Skill",
                        modelValue: _ctx.model.CoreSkill,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.CoreSkill) = $event)),
                        items: _ctx.coreSkills,
                        multiple: "",
                        outlined: "",
                        chips: ""
                      }, null, 8, ["rules", "modelValue", "items"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "6",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_text_field, {
                        dense: "",
                        rules: [_ctx.rules.required],
                        modelValue: _ctx.model.Score,
                        "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.Score) = $event)),
                        label: "Score",
                        outlined: ""
                      }, null, 8, ["rules", "modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    md: "12",
                    sm: "6"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_textarea, {
                        dense: "",
                        label: "Notes",
                        modelValue: _ctx.model.Notes,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.Notes) = $event)),
                        height: "90px",
                        outlined: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                loading: _ctx.loading,
                class: "primary mr-5",
                dark: "",
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("mdi-check")
                    ])),
                    _: 1
                  }),
                  _cache[11] || (_cache[11] = _createTextVNode(" Save"))
                ]),
                _: 1
              }, 8, ["loading", "onClick"]),
              _createVNode(_component_v_btn, {
                disabled: _ctx.loading,
                class: "secondary",
                dark: "",
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode("mdi-cancel")
                    ])),
                    _: 1
                  }),
                  _cache[13] || (_cache[13] = _createTextVNode(" cancel"))
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}