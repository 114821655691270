<template>
  <v-card>
    <v-card-title> Add new assessment </v-card-title>
    <v-card-text>
      <v-form ref="frmAssessment" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-menu :close-on-content-click="true" v-model="datePickerMenu" offset-y>
              <template v-slot:activator="{ props }">
                <v-text-field dense :rules="[rules.required]" v-model="selectedDate" readonly v-bind="props" label="Start date" outlined>
                </v-text-field>
              </template>
              <v-card>
                <v-card-text class="text-center">
                  <v-date-picker first-day-of-week="1" v-model="selectedDate" @update:modelValue="datePickerMenu = false"></v-date-picker>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-combobox
              dense
              :rules="[rules.required]"
              label="Platform"
              v-model="model.Platform"
              :items="['Coderbyte', 'TestDome']"
              multiple
              outlined
              chips
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-combobox
              dense
              :rules="[rules.required]"
              label="Core Skill"
              v-model="model.CoreSkill"
              :items="coreSkills"
              multiple
              outlined
              chips
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field dense :rules="[rules.required]" v-model="model.Score" label="Score" outlined></v-text-field>
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <v-textarea dense label="Notes" v-model="model.Notes" height="90px" outlined></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn :loading="loading" class="primary mr-5" dark @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn :disabled="loading" class="secondary" dark @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import Assessment from "shared-components/src/models/Assessment";
import Utils from "shared-components/src/utils/Utils";
import AssessmentService from "shared-components/src/services/AssessmentService";

export default defineComponent({
  props: ["tmId", "coreSkills", "assessmentObj"],
  data() {
    return {
      valid: false,
      loading: false,
      model: {
        AssessmentDate: null as Date | null,
        CoreSkill: [],
        Notes: "",
        Platform: [],
        Score: 0 as number,
        TeammemberId: this.tmId,
      },
      datePickerMenu: false,
      myDate: null as Date | null,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  methods: {
    async save() {
      const isValid = await (this.$refs.frmAssessment as any).validate();
      if (isValid.valid) {
        this.loading = true;
        const assessmentModel = {
          id: this.assessmentObj ? this.assessmentObj.id : "",
          ...this.model,
        } as Assessment;
        assessmentModel.id = (await AssessmentService.set(assessmentModel)).id;
        this.loading = false;
        this.$emit("saved", assessmentModel);
      }
    },
    cancel() {
      this.loading = false;
      this.$emit("cancel");
    },
  },
  computed: {
    selectedDate: {
      get(): string {
        return this.model.AssessmentDate ? Utils.toVsDateFormat(this.model.AssessmentDate) : "";
      },
      set(date: string | null): void {
        this.model.AssessmentDate = Utils.vsDateToDatetime(date || "");
      },
    },
  },
  mounted() {
    if (this.assessmentObj) {
      this.model.AssessmentDate = this.assessmentObj.AssessmentDate;
      this.model.CoreSkill = this.assessmentObj.CoreSkill;
      this.model.Platform = this.assessmentObj.Platform;
      this.model.Score = this.assessmentObj.Score;
      this.model.Notes = this.assessmentObj.Notes;
    }
  },
});
</script>
