<template>
  <v-card>
    <v-card-title>
      <v-btn icon @click="closeDialog" class="ml-auto" variant="text">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-container>
        <span class="headline">{{ taskTitle }}</span>
      </v-container>

      <v-divider></v-divider>

      <v-form class="form-inputs" ref="frmTaskTemplate">
        <v-container class="py-4">
          <v-row>
            <v-col cols="12" md="6" xs="12" sm="12">
              <TextField
                :rules="[validations.required]"
                id="Task"
                clearable
                label="Task"
                placeholder="Task"
                showRequired
                outlined
                dense
                item-title="Name"
                item-value="Id"
                v-model="newTask.Name"
              >
              </TextField>
            </v-col>
            <v-col cols="12" md="6" xs="12" sm="12">
              <AutoCompleteField
                :rules="[validations.required]"
                :readonly="isHiringmanager"
                v-model="selectedStatus"
                :items="suggestedStatuses"
                dense
                outlined
                label="Status"
                showRequired
                placeholder="Status"
              />
            </v-col>

            <v-col cols="12" md="12" xs="12" sm="12">
              <TextAreaField
                :rules="[validations.required]"
                :readonly="isHiringmanager"
                v-model="newTask.Description"
                dense
                outlined
                label="Description"
                showRequired
                placeholder="Description"
              />
            </v-col>

            <v-col cols="12" md="12" xs="12" sm="12" class="add-button">
              <v-btn color="primary" @click="AddTaskPosition">
                <v-icon left>mdi-check</v-icon>
                Add
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { TaskTemplateApi, TaskTemplateModel, PositionTaskResponseModel } from "shared-components/src/services/openApi/api";
import validations from "shared-components/src/utils/validations";
import store from "@/store";

var taskTemplateApi = new TaskTemplateApi();

export default defineComponent({
  props: {
    editingTask: {
      type: Object as PropType<TaskTemplateModel | null>,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isHiringmanager: {
      type: Boolean,
    },
  },
  data() {
    return {
      valid: true,
      validations,
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      selectedTask: "" as any,
      taskList: [] as TaskTemplateModel[],
      newTask: {
        Id: "",
        Name: "",
        Description: "",
        Status: false,
      } as TaskTemplateModel,
      suggestedStatuses: ["Active", "Archived"],
      Active: false,
      selectedStatus: "" as any,
    };
  },
  mounted() {
    this.getTaskList();

    if (this.editingTask) {
      this.newTask.Description = this.editingTask.Description;
      this.newTask.Id = this.editingTask.Id;
      this.newTask.Name = this.editingTask.Name;
      this.newTask.Status = this.editingTask.Status;

      if (this.newTask.Status === true) {
        this.selectedStatus = "Active";
      } else {
        this.selectedStatus = "Archive";
      }
    }
  },
  computed: {
    taskTitle(): string {
      return this.isEditing ? "Edit Task" : "New Task Template";
    },
  },
  methods: {
    onTaskTemplateSelected(item: any) {
      if (item.Name) {
        this.newTask.Description = item.Description;
      }
    },
    closeDialog() {
      this.$emit("close-task-dialog");
    },
    async getTaskList() {
      await taskTemplateApi.geTaskTemplateList().then((data: any) => {
        this.taskList = data.data;
      });
    },
    async AddTaskPosition() {
      const isValidTask = await (this.$refs.frmTaskTemplate as any).validate();
      if (isValidTask.valid) {
        if (this.isEditing && this.editingTask) {
          this.editingTask.Name = this.newTask.Name;
          this.editingTask.Description = this.newTask.Description;
          this.editingTask.Status = this.selectedStatus === "Active";

          this.$emit("TaskSaved", this.editingTask);

          store.dispatch("showSuccessMessage", "The TaskTemplate has been successfully updated.");
        } else {
          if (this.selectedStatus === "Active") {
            this.newTask.Status = true;
          } else {
            this.newTask.Status = false;
          }

          try {
            const response = await taskTemplateApi.createTaskTemplate(this.newTask);
            this.$emit("TaskSaved", response.data);

            store.dispatch("showSuccessMessage", "The TaskTemplate has been successfully added.");
          } catch (error) {
            store.dispatch("showErrorMessage", "An error occurred: The task could not be added. Please try again.");
            console.error("Error adding task:", error);
          }
        }

        this.newTask = {
          Id: "",
          Name: "",
          Description: "",
          Status: false,
        } as any;

        this.closeDialog();
      }
    },
  },
});
</script>

<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
  font-size: 1.5rem;
}

.task-status {
  font-weight: bold;
  font-size: 1.2rem;
}

.task-details {
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 1rem;
}

.task-section {
  margin-top: 16px;
}

.task-title {
  font-weight: bold;
  display: block;
  margin-bottom: 4px;
}

.add-button {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
</style>
