<template>
  <NotificationDropDown :userId="userId" @errorRaise="errorRaise" @successRaise="successRaise" :viewAllRoute="`/notifications`" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NotificationDropDown from "shared-components/src/components/Notification/NotificationDropDown.vue";
import store from "@/store";

export default defineComponent({
  components: {
    NotificationDropDown,
  },
  data() {
    return {
      userId: store.state.userInfo.id,
    };
  },
  methods: {
    errorRaise(msg: any) {
      store.dispatch("showErrorMessage", msg);
    },
    successRaise(msg: any) {
      store.dispatch("showSuccessMessage", msg);
    },
  },
});
</script>
