import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationDropDown = _resolveComponent("NotificationDropDown")!

  return (_openBlock(), _createBlock(_component_NotificationDropDown, {
    userId: _ctx.userId,
    onErrorRaise: _ctx.errorRaise,
    onSuccessRaise: _ctx.successRaise,
    viewAllRoute: `/notifications`
  }, null, 8, ["userId", "onErrorRaise", "onSuccessRaise"]))
}