<template>
  <v-card>
    <div v-show="!pageLoading">
      <v-card-title>
        <v-row no-gutters>
          <span>{{ commitmentModel && commitmentModel.id ? "Edit" : "Add" }} Commitment</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancel" fab class="error" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="frmCommitment" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="4" sm="6">
              <DateInputField
                :rules="[rules.required]"
                label="Start date"
                first-day-of-week="1"
                v-model="model.StartDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
            <v-col cols="12" md="4" sm="6">
              <DateInputField
                :rules="[rules.required]"
                label="End date"
                first-day-of-week="1"
                v-model="model.EndDate"
                hide-actions
                placeholder="Date"
                prepend-icon=""
              />
            </v-col>
            <v-col cols="12" md="4">
              <SelectField
                :rules="[rules.required]"
                label="Public Holiday Zone"
                :items="publicHolidayZones"
                v-model="model.PublicHolidayZoneId"
                item-title="Name"
                item-value="id"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <TextField
                label="Hours per week"
                :rules="[minMax]"
                type="number"
                dense
                v-model="selectedHoursPerWeekMax"
                :readonly="model.HoursPerWeekRadio"
                min="0"
                outlined
              />
            </v-col>
            <v-col cols="12" md="1">
              <v-checkbox label="Casual" class="mt-7" hide-details v-model="model.HoursPerWeekRadio" @update:modelValue="casualChange" />
            </v-col>
            <v-col cols="12" md="4">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Project Lead"
                v-model="model.ProjectLeadId"
                :items="projectLeads"
                item-title="Name"
                item-value="id"
                outlined
                dense
                :readonly="hasTimesheet"
              />
            </v-col>
            <v-col cols="12" md="4">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Portfolio Manager"
                v-model="model.PortfolioManagerId"
                :items="portfolioManagers"
                item-title="Name"
                item-value="id"
                outlined
                :readonly="hasTimesheet"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Customer"
                v-model="selectedCustomerId"
                :items="customers"
                item-title="Name"
                item-value="id"
                outlined
                dense
                :readonly="hasTimesheet"
                @update:modelValue="onChangeCustomer"
              />
            </v-col>
            <v-col cols="12" md="4">
              <AutoCompleteField
                :loading="projectLoading"
                :rules="[rules.required]"
                label="Project"
                v-model="selectedProjectId"
                :items="projects"
                item-title="Name"
                item-value="id"
                outlined
                :readonly="hasTimesheet"
                dense
              />
            </v-col>
            <v-col cols="12" md="4">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Teammember"
                v-model="selectedTeamMemberId"
                :items="teammembers"
                item-title="Name"
                item-value="id"
                outlined
                dense
                :readonly="hasTimesheet"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-label>Location Type</v-label>
              <v-radio-group inline v-model="model.LocationType" :rules="[rules.required]">
                <v-radio label="Remote" value="Remote"></v-radio>
                <v-radio label="On-Site" value="OnSite"></v-radio>
                <v-radio label="Hybrid" value="Hybrid"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="['Remote', 'OnSite', 'Hybrid'].includes(model.LocationType ?? '')">
            <v-col cols="4">
              <AutoCompleteField :rules="[rules.required]" label="Timezone" v-model="model.TimeZone" :items="timeZones" outlined dense />
            </v-col>
            <v-col cols="4" v-if="model.LocationType != 'Hybrid'">
              <AutoCompleteField
                :rules="[rules.required]"
                label="Days of Week"
                v-model="model.WokingDays"
                :items="workingDays"
                multiple
                outlined
                chips
                dense
                @update:modelValue="DayOfWeekChanged"
              />
            </v-col>
            <v-col cols="2">
              <AutoCompleteField :rules="[rules.required]" label="From" v-model="model.StartWorkClock" :items="hoursInDay" dense outlined />
            </v-col>
            <v-col cols="2">
              <AutoCompleteField :rules="[rules.required]" label="To" v-model="model.EndWorkClock" :items="hoursInDay" dense outlined />
            </v-col>
          </v-row>
          <v-row v-if="['Remote', 'OnSite', 'Hybrid'].includes(model.LocationType ?? '')">
            <v-col cols="4" v-if="model.LocationType != 'Remote'">
              <AutoCompleteField
                outlined
                dense
                :rules="[rules.required]"
                :items="officeLocations"
                item-title="Name"
                item-value="Id"
                label="Office Location"
                showRequired
                v-model="model.OfficeLocationId"
                @update:modelValue="checkOfficeSpaceStatus"
              >
                <template v-slot:append>
                  <v-btn icon @click="showAddOfficeLocation = true" v-if="selectedCustomerId"><v-icon>mdi-plus</v-icon></v-btn>
                </template>
              </AutoCompleteField>
            </v-col>
            <v-col cols="4" v-if="model.LocationType == 'Hybrid'">
              <AutoCompleteField
                v-model="model.OfficeDaysPerWeek"
                :rules="[rules.required]"
                label="No. Of Office Days Per Week"
                :items="NoOfficeDaysPerWeek"
                outlined
                dense
              />
            </v-col>
            <v-col cols="4" v-if="model.LocationType == 'Hybrid'">
              <AutoCompleteField
                v-model="model.DaysOfWeekInOffice"
                :rules="[rules.required]"
                label="Days Of Week In The Office"
                :items="dayInOffice"
                multiple
                outlined
                chips
                dense
                @update:modelValue="DaysInOfficeChanged"
                :return-object="false"
              />
            </v-col>
            <v-col cols="2"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card flat>
                <v-card-title>Addons</v-card-title>
                <v-card-text>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-title :class="{ active: timeSheetProcessing.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Timesheet Processing </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <TimeSheetProcessingAddon :timeSheetProcessing="timeSheetProcessing" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title :class="{ active: payRoll.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Payroll </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <PayrollAddon :payRoll="payRoll" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title :class="{ active: contract.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Contract </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <ContractAddon :contract="contract" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title v-slot="{ expanded }" :class="{ active: virtualDesktopSpec.Enabled }">
                        <v-row no-gutters>
                          <v-col cols="4"> Virtual Desktop </v-col>
                        </v-row>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <VirtualDesktopAddon
                          :showEnableCheckBox="true"
                          :virtualDesktopSpec="virtualDesktopSpec"
                          :diskValues="diskValues"
                          :cpuValues="cpuValues"
                          :ramValues="ramValues"
                        />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel v-if="model.LocationType != 'Remote' && model.OfficeLocationId == 'OfficeSpace'">
                      <v-expansion-panel-title :class="{ active: officeSpace.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Office Space </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <OfficeSpaceAddon :calculateOfficeSpace="calculateOfficeSpace" :officeSpace="officeSpace" :officeSpaces="officeSpaces" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title :class="{ active: lineManagerAddon.Enabled }">
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Line Manager </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                      <v-expansion-panel-text>
                        <LineManagerAddon :lineManagerAddon="lineManagerAddon" :lineManagers="lineManagers" />
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title>
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Traning Roadmaps </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-title>
                        <template v-slot:default="{ expanded }">
                          <v-row no-gutters>
                            <v-col class="d-flex justify-start" cols="4"> Talent Development Manager </v-col>
                          </v-row>
                        </template>
                      </v-expansion-panel-title>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <v-card flat>
          <v-card-title>Messages</v-card-title>
          <v-card-text class="messages-container">
            <div class="message-input">
              <input type="text" placeholder="Enter your message" v-model="newMessage" />
              <div class="send-buttons">
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-icon size="x-large" v-bind="props" @click="sendMessage('Client')">mdi-send-circle </v-icon>
                  </template>
                  <span>Send to Customer</span>
                </v-tooltip>
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-icon size="x-large" v-bind="props" @click="sendMessage('Teammember')">mdi-send-circle-outline </v-icon>
                  </template>
                  <span>Send to Teammember</span>
                </v-tooltip>
              </div>
            </div>
            <div class="message-body" ref="messageBody">
              <div
                v-for="(item, index) in notifications"
                :key="index"
                :class="{ 'message-item': true, 'message-left': !isOwner(item), 'message-right': isOwner(item) }"
              >
                <div class="message-item-body">
                  <img class="user-image" src="@/assets/images/no-profile.png" />
                  {{ item.Text }}
                </div>
                <div class="message-item-details">
                  <span> {{ getNotificationCreatedOn(item.CreationDate?.toString()) }}</span>
                  <div class="message-item-details-icon">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ props }">
                        <img class="icon" v-bind="props" src="@/assets/images/sender-message-icon.png" />
                      </template>
                      <span>Sent By: {{ item.CreatorUser?.UserFullName }}</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ props }">
                        <img class="icon" v-bind="props" src="@/assets/images/receiver-message-icon.png" />
                      </template>
                      <span>Received By: {{ getReceiverNames(item.Users) }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-col md="12">
          <v-btn :loading="loading" class="primary_btn mr-5" dark @click="showUpdateStatus(false)">Approve</v-btn>
          <v-btn :loading="loading" class="secondary_btn mr-5" dark @click="showUpdateStatus(true)">Reject</v-btn>
          <v-btn :loading="loading" class="primary_btn mr-5" dark @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
          <v-btn :loading="loading" class="secondary_btn" dark @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
        </v-col>
      </v-card-actions>
    </div>
    <loading-component v-if="pageLoading"></loading-component>
    <v-dialog v-model="showAddOfficeLocation" persistent max-width="400px">
      <AddOfficeLocation @cancel="showAddOfficeLocation = false" @saveLocation="saveOfficeLocation" :customerId="selectedCustomerId" />
    </v-dialog>
    <v-dialog v-model="showUpdateStatusPopup" persistent max-width="400px">
      <v-card>
        <v-card-title>
          {{ !rejectMode ? "Approve Commitment" : "Reject Commitment" }}
        </v-card-title>
        <v-card-text>
          <span>Are you sure about this action ?</span>
          <v-textarea v-if="rejectMode" v-model="rejectMessage" label="Reason of Rejecting" :rules="[rules.required]" />
        </v-card-text>
        <v-card-actions>
          <v-col md="12">
            <v-btn :loading="loading" class="primary mr-5" dark @click="updateCommitmentStatus">{{
              !rejectMode ? "Yes! Approve it" : "Yes! Reject it"
            }}</v-btn>
            <v-btn :loading="loading" class="secondary" dark @click="showUpdateStatusPopup = false">Cancel</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import PublicHolidayService from "shared-components/src/services/PublicHolidayService";
import ProjectService from "shared-components/src/services/ProjectService";
import LoadingComponent from "shared-components/src/components/Loading.vue";
import Commitment from "shared-components/src/models/Commitment";
import CustomerService from "../../services/CustomerService";
import LineManagerService from "shared-components/src/services/LineManagerService";
import CommitmentService from "shared-components/src/services/CommitmentService";
import ProjectLeadService from "shared-components/src/services/ProjectLeadService";
import TeamMemberService from "shared-components/src/services/TeamMemberService";
import PortfolioManagerService from "../../services/PortfolioManagerService";
import TDMService from "../../services/TDMService";
import Utils from "shared-components/src/utils/Utils";
import Rate from "./Rate.vue";
import AppHelper from "shared-components/src/utils/AppHelper";
import AddOfficeLocation from "./AddOfficeLocation.vue";
import {
  OfficeLocationModel,
  OfficeSpaceModel,
  SendCommitmentNotificationRequestModel,
  UpdateCommitmentStatusRequestModel,
} from "shared-components/src/services/openApi/api";
import moment from "moment";
import OfficeSpaceService from "@/services/OfficeSpaceService";
import { NotificationModel } from "shared-components/src/services/openApi";
import NotificationService from "@/services/NotificationService";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import { UserOfNotification } from "shared-components/src/services/openApi";
import store from "@/store";
import PayrollAddon from "shared-components/src/components/Commitment/Addons/PayrollAddon.vue";
import ContractAddon from "shared-components/src/components/Commitment/Addons/ContractAddon.vue";
import VirtualDesktopAddon from "shared-components/src/components/Commitment/Addons/VirtualDesktopAddon.vue";
import LineManagerAddon from "shared-components/src/components/Commitment/Addons/LineManagerAddon.vue";
import OfficeSpaceAddon from "shared-components/src/components/Commitment/Addons/OfficeSpaceAddon.vue";
import TimeSheetProcessingAddon from "shared-components/src/components/Commitment/Addons/TimeSheetProcessingAddon.vue";

var momentTz = require("moment-timezone");

export default defineComponent({
  components: {
    LoadingComponent,
    Rate,
    AddOfficeLocation,
    TextField,
    PayrollAddon,
    ContractAddon,
    VirtualDesktopAddon,
    LineManagerAddon,
    OfficeSpaceAddon,
    TimeSheetProcessingAddon,
  },
  props: ["commitmentModel", "commitments"],
  data() {
    return {
      showUpdateStatusPopup: false,
      rejectMode: false,
      rejectMessage: "",
      newMessage: "",
      ramValues: {
        0: '8',
        1: '16',
        2: '24',
        3: '32',
      },
      cpuValues: {
        0: '2',
        1: '4',
        2: '6',
      },
      diskValues: {
        0: '128',
        1: '256',
        2: '512',
      },
      daysTemplate: [
        { title: "Flexible", value: "Flexible", props: { disabled: false } },
        { title: "SUN", value: "SUN", props: { disabled: false } },
        { title: "MON", value: "MON", props: { disabled: false } },
        { title: "TUE", value: "TUE", props: { disabled: false } },
        { title: "WED", value: "WED", props: { disabled: false } },
        { title: "THR", value: "THR", props: { disabled: false } },
        { title: "FRI", value: "FRI", props: { disabled: false } },
        { title: "SAT", value: "SAT", props: { disabled: false } },
      ],
      officeSpace: {
        Enabled: false,
        officeSpaceId: null as string | null,
      },
      virtualDesktopSpec: {
        AdditionalSecurityBL: false,
        AdditionalSecurityNG: false,
        Cpu: 0,
        Disk: 0,
        Enabled: false,
        Level: null as string | null,
        Location: null as string | null,
        OperatingSystem: null as string | null,
        Policy: null as string | null,
        Ram: 0,
      },
      timeSheetProcessing: {
        Enabled: false,
      },
      lineManagerAddon: {
        Enabled: false,
        LineManagerId: null as string | null,
      },
      payRoll: {
        Enabled: false,
        Amount: 0,
        Currency: null as string | null,
        Salary: null as string | null,
      },
      contract: {
        Enabled: false,
        ContractManagement: false,
        ContactVerification: false,
        IDVerification: false,
      },
      workingDays: [{ text: "Flexible", value: "Flexible", props: { disabled: false } }],
      dayInOffice: [{ text: "Flexible", value: "Flexible", props: { disabled: false } }],
      hoursInDay: ["Flexible"],
      NoOfficeDaysPerWeek: ["Flexible", 1, 2, 3, 4, 5, 6, 7],
      hasTimesheet: false,
      pageLoading: true,
      loading: false,
      valid: true,
      showRating: false,
      endDatePickerMenu: false,
      startDatePickerMenu: false,
      projectLoading: false,
      publicHolidayZones: [] as Array<{ id: string; Name: string }>,
      lineManagers: [] as Array<{ id: string; Name: string }>,
      projectLeads: [] as Array<{ id: string; Name: string }>,
      portfolioManagers: [] as Array<{ id: string; Name: string }>,
      tdms: [] as Array<{ id: string; Name: string }>,
      projects: [] as Array<{ id: string; Name: string }>,
      customers: [] as Array<{ id: string; Name: string }>,
      teammembers: [] as Array<{ id: string; Name: string }>,
      model: {} as Commitment,
      selectedCustomerId: null as string | null,
      selectedTeamMemberId: null as string | null,
      selectedProjectId: null as string | null,
      timeZones: [] as Array<{ title: string; value: string }>,
      showAddOfficeLocation: false,
      StartDate: null as Date | null,
      EndDate: null as Date | null,
      officeSpaces: [] as OfficeSpaceModel[],
      notifications: [] as NotificationModel[],
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
        exist: (v: any) =>
          !this.commitments.some(
            (c: any) => c.Name && v && c.Name.toLowerCase() === v.toLowerCase() && !(this.commitmentModel && c.id === this.commitmentModel.id)
          ) || "Already exist",
      },
      officeLocations: [
        {
          Address: "Choose Office Space From CodeClan",
          Id: "OfficeSpace",
          Name: "Choose Office Space From CodeClan",
        },
      ] as OfficeLocationModel[],
    };
  },
  async mounted() {
    await this.fetchPublicHolidays();
    await this.fetchCustomers();
    await this.fetchTeammembers();
    await this.fetchLineManager();
    await this.loadOfficeSpaces();
    await this.loadNotifications();
    this.loadTimeZones();
    this.fillHoursInDay();
    this.workingDays = JSON.parse(JSON.stringify(this.daysTemplate));
    this.dayInOffice = JSON.parse(JSON.stringify(this.daysTemplate));
    this.pageLoading = false;
  },
  methods: {
    getNotificationCreatedOn(date: string | undefined) {
      if (date) {
        return moment(date).format("YYYY-MM-DD HH:MM");
      }
      return "";
    },
    getReceiverNames(users: UserOfNotification[] | undefined) {
      if (users) {
        return users.map((item) => item.UserFullName).join(",");
      }
      return "";
    },
    async updateCommitmentStatus() {
      if (this.commitmentModel && this.commitmentModel.id) {
        this.loading = true;
        let request;
        if (this.rejectMode) {
          request = { message: this.rejectMessage, status: "Rejected" } as UpdateCommitmentStatusRequestModel;
        } else {
          request = { status: "Approved" } as UpdateCommitmentStatusRequestModel;
        }
        await CommitmentService.adminUpdateStatus(this.commitmentModel.id, request);
        this.showUpdateStatusPopup = false;
        this.rejectMessage = "";
        await this.loadNotifications();
        this.loading = false;
      }
    },
    showUpdateStatus(rejectMode: boolean) {
      this.rejectMode = rejectMode;
      this.showUpdateStatusPopup = true;
    },
    async sendMessage(type: string) {
      if (this.commitmentModel && this.commitmentModel.id && this.newMessage != "") {
        const request = { message: this.newMessage, receiverType: type } as SendCommitmentNotificationRequestModel;
        const response = await NotificationService.sendCommitmentNotification(this.commitmentModel.id, request);
        this.notifications.push(response);
        this.newMessage = "";
        setTimeout(() => {
          const lastChildElement = (this.$refs.messageBody as any).lastElementChild;
          lastChildElement?.scrollIntoView({ behavior: "smooth" });
        }, 200);
      }
    },
    isOwner(message: NotificationModel) {
      if (message.CreatorUserId == store.state.userInfo.id) {
        return true;
      } else {
        return false;
      }
    },
    fillHoursInDay() {
      this.hoursInDay = ["Flexible"];
      this.hoursInDay = this.hoursInDay.concat(
        ...Array.from(Array(24), (_, hour) => [moment({ hour }).format("h:mm A"), moment({ hour, minute: 30 }).format("h:mm A")])
      );
    },
    async loadOfficeLocations() {
      if (this.selectedCustomerId) {
        const response = await CustomerService.GetOfficeLocations(this.selectedCustomerId);
        this.officeLocations = this.officeLocations.concat(response);
      }
    },
    async loadOfficeSpaces() {
      this.officeSpaces = await OfficeSpaceService.GetOfficeSpaces();
    },
    async loadNotifications() {
      if (this.commitmentModel && this.commitmentModel.id) {
        this.notifications = await NotificationService.getByCommitmentId(this.commitmentModel.id);
      }
    },
    loadTimeZones() {
      var timeZones = momentTz.tz.names();

      for (var i in timeZones) {
        this.timeZones.push({
          title: timeZones[i] + " (GMT" + momentTz.tz(timeZones[i]).format("Z") + ")",
          value: timeZones[i],
        });
      }
    },
    casualChange() {
      this.selectedHoursPerWeekMax = null;
    },
    async fetchPublicHolidays(): Promise<void> {
      this.publicHolidayZones = (await PublicHolidayService.getList(true)).map((item: any) => {
        return { id: item.Id, Name: item.Title };
      });
    },

    async fetchLineManager(): Promise<void> {
      this.lineManagers = (await LineManagerService.getList(true))
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchProjectLeads(): Promise<void> {
      this.projectLeads = (await ProjectLeadService.getList(true))
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchPortfolioManagers(): Promise<void> {
      this.portfolioManagers = (await PortfolioManagerService.getList())
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchTDMs(): Promise<void> {
      this.tdms = (await TDMService.getList())
        .filter((c: any) => c.FirstName || c.LastName)
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },

    async fetchCustomers(): Promise<void> {
      this.customers = (await CustomerService.getList())
        .filter((c: any) => (c.FirstName && c.FirstName.trim()) || (c.LastName && c.LastName.trim()))
        .map((item: any) => {
          return { id: item.id, Name: item.FirstName + " " + item.LastName };
        });
    },
    async fetchTeammembers(): Promise<void> {
      if (AppHelper.IsAzureEnv()) {
        this.teammembers = (await TeamMemberService.getListWithSummaryDetails())
          .filter((c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
          .map((item: any) => {
            return { id: item.Id, Name: item.FirstName + " " + item.LastName };
          });
      } else {
        this.teammembers = (await TeamMemberService.getList(true))
          .filter((c: any) => c.FirstName && c.FirstName.trim() && c.LastName && c.LastName.trim())
          .map((item: any) => {
            return { id: item.Id, Name: item.FirstName + " " + item.LastName };
          });
      }
    },
    cancel() {
      (this.$refs.frmCommitment as any).reset();
      this.loading = false;
      this.$emit("cancel");
    },
    async save() {
      const isValid = await (this.$refs.frmCommitment as any).validate();
      if (isValid.valid) {
        this.loading = true;
        const model = { ...this.model };
        model.id = this.commitmentModel ? this.commitmentModel.id : "";
        model.OfficeLocationId = model.OfficeLocationId == "OfficeSpace" ? null : model.OfficeLocationId;
        model.TeamMemberIds = [this.selectedTeamMemberId ?? ""];
        model.ProjectIds = [this.selectedProjectId ?? ""];
        model.CustomerIds = [this.selectedCustomerId ?? ""];
        model.TimesheetProcessing = this.timeSheetProcessing.Enabled;
        if (this.virtualDesktopSpec.Enabled) {
          model.VirtualDesktopSpec = this.virtualDesktopSpec;
          model.VirtualDesktopRate = 10;
          model.VirtualDesktopSpec.Cpu = Number(Object.values(this.cpuValues)[model.VirtualDesktopSpec.Cpu]);
          model.VirtualDesktopSpec.Ram = Number(Object.values(this.ramValues)[model.VirtualDesktopSpec.Ram]);
          model.VirtualDesktopSpec.Disk = Number(Object.values(this.diskValues)[model.VirtualDesktopSpec.Disk]);
        } else {
          model.VirtualDesktopSpec = null;
          model.VirtualDesktopRate = null;
        }
        if (this.lineManagerAddon.Enabled) {
          model.LineManagerId = this.lineManagerAddon.LineManagerId;
        } else {
          model.LineManagerId = null;
        }
        if (this.payRoll.Enabled) {
          if (this.payRoll.Salary == "Hourly") {
            model.SeniorityLevel = this.payRoll.Amount;
            model.SalaryMonthly = 0;
            model.SalaryCurrency = this.payRoll.Currency;
          } else if (this.payRoll.Salary == "Monthly") {
            model.SeniorityLevel = 0;
            model.SalaryMonthly = this.payRoll.Amount;
            model.SalaryCurrency = this.payRoll.Currency;
          }
        } else {
          model.SeniorityLevel = 0;
          model.SalaryMonthly = null;
          model.SalaryCurrency = null;
        }
        if (this.contract.Enabled) {
          model.ContactVerification = this.contract.ContactVerification;
          model.ContractManagement = this.contract.ContractManagement;
          model.IDVerification = this.contract.IDVerification;
        } else {
          model.ContactVerification = false;
          model.ContractManagement = false;
          model.IDVerification = false;
        }
        if (this.officeSpace.Enabled) {
          model.OfficeSpaceId = this.officeSpace.officeSpaceId;
        } else {
          model.OfficeSpaceId = null;
        }
        if (model.id) {
          model.id = (await CommitmentService.update(model)).id;
        } else {
          model.id = (await CommitmentService.setV2(model)).id;
        }
        this.$emit("saved", model);

        this.loading = false;
      }
    },
    DayOfWeekChanged() {
      if (this.model.WokingDays.includes("Flexible")) {
        this.model.WokingDays = ["Flexible"];
        this.workingDays.forEach((item: any) => (item.text != "Flexible" ? (item.props.disabled = true) : (item.props.disabled = false)));
      } else {
        this.workingDays.forEach((item: any) => (item.props.disabled = false));
      }
    },
    DaysInOfficeChanged() {
      if (this.model.DaysOfWeekInOffice && this.model.DaysOfWeekInOffice.includes("Flexible")) {
        this.model.DaysOfWeekInOffice = ["Flexible"];
        this.dayInOffice.forEach((item: any) => (item.text != "Flexible" ? (item.props.disabled = true) : (item.props.disabled = false)));
      } else {
        this.dayInOffice.forEach((item: any) => (item.props.disabled = false));
      }
    },
    minMax(v: any): boolean | string {
      return this.model.HoursPerWeekRadio || !!v || "Required.";
    },
    async onChangeCustomer() {
      this.selectedProjectId = null;
      this.projects = await this.loadProjects();
      await this.loadOfficeLocations();
    },
    async loadProjects(): Promise<Array<{ id: string; Name: string }>> {
      this.projectLoading = true;
      return new Promise((resolve, reject) => {
        if (this.selectedCustomerId) {
          const customerId = this.selectedCustomerId;
          ProjectService.getByCustomerId(customerId)
            .then((list: any) => {
              resolve(
                list.map((c: any) => {
                  return {
                    id: c.id,
                    Name: c.Name,
                  };
                })
              );
              this.projectLoading = false;
            })
            .catch((error: any) => {
              this.projectLoading = false;
              reject(error);
            });
        } else {
          resolve([]);
        }
      });
    },
    async loadData(): Promise<void> {
      await this.fetchLineManager();
      await this.fetchProjectLeads();
      await this.fetchPortfolioManagers();
      await this.fetchTDMs();
      if (this.commitmentModel) {
        this.loading = true;

        let commitment = this.commitmentModel;
        if (this.commitmentModel.id) {
          commitment = await CommitmentService.get(this.commitmentModel.id);
        }
        if (commitment) {
          this.model = {
            Name: commitment.Name,
            IsActive: commitment.IsActive,
            HoursPerWeekRadio: commitment.HoursPerWeekRadio,
            HoursPerWeekMin: commitment.HoursPerWeekMin ? parseFloat(commitment.HoursPerWeekMin.toString()) : null,
            SeniorityLevel: commitment.SeniorityLevel,
            StartDate: commitment.StartDate,
            EndDate: commitment.EndDate,
            HoursPerWeekMax: commitment.HoursPerWeekMax ? parseFloat(commitment.HoursPerWeekMax.toString()) : null,
            WokingDays: commitment.WokingDays ? commitment.WokingDays : [],
            LineManagerId: commitment.LineManagerId,
            ProjectLeadId: commitment.ProjectLeadId,
            PortfolioManagerId: commitment.PortfolioManagerId,
            PublicHolidayZoneId: commitment.PublicHolidayZoneId,
            TDMId: commitment.TDMId,
            AdminApproval: commitment.AdminApproval,
            CustomerApproval: commitment.CustomerApproval,
            TeamMemberApproval: commitment.TeamMemberApproval,
            TimeZone: commitment.TimeZone,
            StartWorkClock: commitment.StartWorkClock,
            EndWorkClock: commitment.EndWorkClock,
            DaysOfWeekInOffice: commitment.DaysOfWeekInOffice,
            OfficeDaysPerWeek: commitment.OfficeDaysPerWeek,
            OfficeLocationId: commitment.OfficeLocationId == null ? "OfficeSpace" : commitment.OfficeLocationId,
            LocationType: commitment.LocationType,
            Rate: {},
          } as Commitment;
          this.timeSheetProcessing.Enabled = commitment.TimesheetProcessing;
          if (commitment.VirtualDesktopSpec != null) {
            this.virtualDesktopSpec = commitment.VirtualDesktopSpec;
            this.virtualDesktopSpec.Cpu = Number(
              Object.keys(this.cpuValues).find((key) => (this.cpuValues as any)[key] == commitment.VirtualDesktopSpec.Cpu)
            );
            this.virtualDesktopSpec.Ram = Number(
              Object.keys(this.ramValues).find((key) => (this.ramValues as any)[key] == commitment.VirtualDesktopSpec.Ram)
            );
            this.virtualDesktopSpec.Disk = Number(
              Object.keys(this.diskValues).find((key) => (this.diskValues as any)[key] == commitment.VirtualDesktopSpec.Disk)
            );
          }
          if (commitment.LineManagerId != null) {
            this.lineManagerAddon.Enabled = true;
            this.lineManagerAddon.LineManagerId = commitment.LineManagerId;
          }
          if (commitment.SalaryCurrency != null) {
            this.payRoll.Enabled = true;
            this.payRoll.Currency = commitment.SalaryCurrency;
            if (commitment.SalaryMonthly == 0) {
              this.payRoll.Amount = commitment.SeniorityLevel;
              this.payRoll.Salary = "Hourly";
            } else {
              this.payRoll.Amount = commitment.SalaryMonthly;
              this.payRoll.Salary = "Monthly";
            }
          }
          if (commitment.ContactVerification == true || commitment.ContractManagement == true || commitment.IDVerification == true) {
            this.contract.Enabled = true;
            this.contract.ContactVerification = commitment.ContactVerification;
            this.contract.ContractManagement = commitment.ContractManagement;
            this.contract.IDVerification = commitment.IDVerification;
          }
          if (commitment.OfficeSpaceId != null) {
            this.officeSpace.Enabled = true;
            this.officeSpace.officeSpaceId = commitment.OfficeSpaceId;
          }
          if (commitment.Customers && commitment.Customers.length > 0) {
            this.selectedCustomerId = commitment.Customers[0].id;
            this.projects = await this.loadProjects();
          }
          if (commitment.TeamMembers) {
            this.selectedTeamMemberId = commitment.TeamMembers[0].id;
          }
          if (commitment.ProjectIds && commitment.ProjectIds.length > 0) {
            const filteredProjects = this.projects.filter((item) => commitment.ProjectIds[0] == item.id);
            if (filteredProjects && filteredProjects.length > 0) {
              this.selectedProjectId = this.projects.filter((item) => commitment.ProjectIds[0] == item.id)[0].id;
            }
          }
          this.hasTimesheet = commitment.HasTimesheet;
        }
        this.loading = false;
      }
    },
    saveOfficeLocation(event: any) {
      this.showAddOfficeLocation = false;
      this.officeLocations.push(event);
    },
    checkOfficeSpaceStatus() {
      if (this.model.OfficeLocationId == "OfficeSpace") {
        this.officeSpace.Enabled = true;
      } else {
        this.officeSpace.Enabled = false;
      }
    },
  },
  computed: {
    calculateOfficeSpace() {
      let message = "You Selected";
      if (this.model.LocationType == "OnSite") {
        if (
          this.model.StartWorkClock == "Flexible" ||
          this.model.EndWorkClock == "Flexible" ||
          (this.model.WokingDays && this.model.WokingDays.includes("Flexible"))
        ) {
          return false;
        }
        const startTime = moment(this.model.StartWorkClock, "h:mm A");
        const endTime = moment(this.model.EndWorkClock, "h:mm A");
        const duration = moment.duration(endTime.diff(startTime));
        message = `${message} ${this.model.WokingDays.length} days per week (${this.model.WokingDays.join(",")}) and
          ${duration.asHours()} Hours per day`;
        return message;
      } else if (this.model.LocationType == "Hybrid") {
        if (this.model.StartWorkClock == "Flexible" || this.model.EndWorkClock == "Flexible" || this.model.OfficeDaysPerWeek == "Flexible") {
          return false;
        }
        const startTime = moment(this.model.StartWorkClock, "h:mm A");
        const endTime = moment(this.model.EndWorkClock, "h:mm A");
        const duration = moment.duration(endTime.diff(startTime));
        message = `${message} ${this.model.OfficeDaysPerWeek} days per week in these days (${this.model.DaysOfWeekInOffice?.join(
          ","
        )}) and ${duration.asHours()} Hours per day`;
        return message;
      }
    },
    selectedHoursPerWeekMax: {
      get(): number | null {
        return this.model.HoursPerWeekMax;
      },
      set(value: any): void {
        this.model.HoursPerWeekMax = value ? parseFloat(value) : null;
      },
    },
    formatedStartDate() {
      return this.model.StartDate ? Utils.toVsDateFormat(this.model.StartDate) : null;
    },
    formatedEndDate() {
      return this.model.EndDate ? Utils.toVsDateFormat(this.model.EndDate) : null;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "node_modules/shared-components/assets/style/color.scss";

.active {
  background-color: $c_emerald;
}

.messages-container {
  background-color: #c1c1c1;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  .message-body {
    overflow-y: auto;
    min-height: 200px;
    max-height: 450px;
    .message-item {
      display: flex;
      align-items: center;

      &.message-left {
        .message-item-body {
          background-color: white;
          color: black;
          padding-left: 50px;
          .user-image {
            left: 0;
          }
        }
      }
      &.message-right {
        flex-flow: row-reverse;
        margin-left: auto;
        .message-item-details {
          flex-flow: row-reverse;
          .message-item-details-icon {
            flex-flow: row-reverse;
          }
        }
        .message-item-body {
          background-color: black;
          color: white;
          padding-right: 50px;
          .user-image {
            right: 0;
          }
        }
      }
      .message-item-details {
        display: flex;
        gap: 10px;
        align-items: center;
        .message-item-details-icon {
          display: flex;
          gap: 10px;
          align-items: center;
          img {
            width: 25px;
            height: 25px;
            cursor: pointer;
          }
        }
      }
      .message-item-body {
        border-radius: 15px;
        padding: 10px;
        width: max-content;
        margin: 15px;
        height: fit-content;
        position: relative;

        .user-image {
          border-radius: 25px;
          width: 40px;
          height: 40px;
          position: absolute;
          background-color: white;
          bottom: 10px;
        }
      }
    }
  }

  .message-input {
    background-color: rgb(36, 36, 36);
    height: 40px;
    padding: 0 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
    display: flex;
    align-items: normal;
    justify-content: space-between;
    input {
      padding: 15px;
      color: white;
      flex: 1;
      outline: none;
    }
    .send-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      * {
        color: white;
        cursor: pointer;
      }
    }
  }
}
</style>
