<template>
  <notification-list v-if="userId" :userId="userId" :isClient="false" />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import NotificationList from "shared-components/src/components/Notification/NotificationList.vue";
import { mapGetters } from "vuex";
import store from "@/store";

export default defineComponent({
  components: {
    NotificationList,
  },
  data() {
    return {
      userId: store.state.userInfo.id,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
});
</script>
