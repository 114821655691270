import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline" }
const _hoisted_2 = { class: "task-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: _ctx.closeDialog,
            class: "ml-auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.job.Title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_container, { class: "py-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, {
                align: "center",
                class: "mb-4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_avatar, {
                        color: _ctx.avatarColor,
                        size: "12"
                      }, null, 8, ["color"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.job.Status), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-details"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("mdi-calendar")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { top: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps({ class: "mr-8" }, props), _toDisplayString(_ctx.formatDate(_ctx.job.StartDate)), 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[2] || (_cache[2] = _createElementVNode("span", null, "Start Date", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[3] || (_cache[3] = [
                          _createTextVNode("mdi-account-multiple")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { top: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps({ class: "mr-8" }, props), _toDisplayString(_ctx.job.NoOfPositions) + " positions", 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createElementVNode("span", null, "Number of Positions", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("mdi-map-marker")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { top: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps({ class: "mr-8" }, props), _toDisplayString(_ctx.getCountryName(_ctx.job.Country)), 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[6] || (_cache[6] = _createElementVNode("span", null, "Country", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createTextVNode("mdi-account-tie")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { top: "" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps({ class: "mr-8" }, props), _toDisplayString(_ctx.selectedCutomerName), 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[8] || (_cache[8] = _createElementVNode("span", null, "Cusomer", -1))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[9] || (_cache[9] = _createElementVNode("span", { class: "task-title" }, "Project Name", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.ProjectName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "task-title" }, "Skills", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.job.Skills, (skill) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: skill.id
                        }, _toDisplayString(skill.Text), 1))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[11] || (_cache[11] = _createElementVNode("span", { class: "task-title" }, "Job Titles", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.job.JobTitles, (title) => {
                        return (_openBlock(), _createElementBlock("span", {
                          key: title.id
                        }, _toDisplayString(title.Name), 1))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[12] || (_cache[12] = _createElementVNode("span", { class: "task-title" }, "Job Role", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.selectedJobRole), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[13] || (_cache[13] = _createElementVNode("span", { class: "task-title" }, "Hiring Contact", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.HiringContact), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "6",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[14] || (_cache[14] = _createElementVNode("span", { class: "task-title" }, "Hiring Manager", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.HiringmanagerName), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[15] || (_cache[15] = _createElementVNode("span", { class: "task-title" }, "Job Description", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.job.JobDescription), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-table"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_data_table, {
                        headers: _ctx.headerTask,
                        items: _ctx.job.Tasks,
                        "hide-default-footer": true
                      }, {
                        "item.task": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.Title), 1)
                        ]),
                        "item.taskDate": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(_ctx.formatDate(item.Date)), 1)
                        ]),
                        "item.order": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.OrderNumber), 1)
                        ]),
                        "item.Description": _withCtx(({ item }) => [
                          _createTextVNode(_toDisplayString(item.Description), 1)
                        ]),
                        _: 1
                      }, 8, ["headers", "items"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}