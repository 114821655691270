<template>
  <v-card>
    <v-card-title> Add new Review </v-card-title>
    <v-card-text>
      <v-form ref="frmReview" v-model="valid" lazy-validation>
        <v-row>
          <!-- Customers -->
          <v-col cols="12" md="6" sm="6">
            <v-combobox
              dense
              :rules="[rules.required]"
              label="Customer"
              v-model="model.Customer"
              :items="customers"
              item-title="Name"
              item-value="id"
              outlined
              chips
            ></v-combobox>
          </v-col>
          <!-- Date -->
          <v-col cols="12" md="6" sm="6">
            <v-menu :close-on-content-click="true" v-model="datePickerMenu" offset-y>
              <template v-slot:activator="{ props }">
                <v-text-field dense :rules="[rules.required]" v-model="selectedDate" readonly v-bind="props" label="Date" outlined>
                </v-text-field>
              </template>
              <v-card>
                <v-card-text class="text-center">
                  <v-date-picker first-day-of-week="1" v-model="selectedDate" @update:modelValue="datePickerMenu = false"></v-date-picker>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
          <!-- Weakness -->
          <v-col cols="12" md="6" sm="6">
            <v-combobox
              dense
              :rules="[rules.required]"
              label="Weakness"
              v-model="model.Weakness"
              :items="traits"
              item-title="Name"
              item-value="Id"
              outlined
              chips
            ></v-combobox>
          </v-col>
          <!-- Strength -->
          <v-col cols="12" md="6" sm="6">
            <v-combobox
              dense
              :rules="[rules.required]"
              label="Strength"
              v-model="model.Strength"
              :items="traits"
              item-title="Name"
              item-value="Id"
              outlined
              chips
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <!-- <star-rating :increment="0.5" text-class="custom-text" v-model="model.Rating" /> -->
          </v-col>
          <v-col cols="12" md="12" sm="6">
            <v-textarea dense label="Notes" v-model="model.Text" height="90px" outlined></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-col md="12">
        <v-btn :loading="loading" class="primary mr-5" dark @click="save"><v-icon>mdi-check</v-icon> Save</v-btn>
        <v-btn :disabled="loading" class="secondary" dark @click="cancel"><v-icon>mdi-cancel</v-icon> cancel</v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import { Review } from "shared-components/src/models/Review";
import Utils from "shared-components/src/utils/Utils";
import ReviewService from "shared-components/src/services/ReviewService11";
//import StarRating from "vue-star-rating";
import AppHelper from "shared-components/src/utils/AppHelper";

export default defineComponent({
  props: ["tmId", "reviewObj", "customers", "traits"],
  components: {
   //StarRating,
  },
  data() {
    return {
      loading: false,
      valid: false,
      model: {
        ReviewDate: null as Date | null,
        Customer: "",
        Rating: 0,
        Weakness: "",
        Strength: "",
        Text: "",
      },
      datePickerMenu: false,
      rules: {
        required: (value: any) => (!(value instanceof Array) && !!value) || (value instanceof Array && value.length > 0) || "Required.",
        float: (v: any) => !v || /^[0-9]+(.[0-9]+)?$/gi.test(v) || "Just number is valid",
      },
    };
  },
  methods: {
    async save() {
      const isValid = await (this.$refs.frmReview as any).validate();
      if (isValid.valid) {
        this.loading = true;
        const reviewModel = {
          Id: this.reviewObj ? this.reviewObj.Id : "",
          ...this.model,
        } as Review;
        if (!AppHelper.IsAzureEnv()) {
          reviewModel.Id = (await ReviewService.create(this.tmId, reviewModel)).Id;
        }

        this.loading = false;
        this.$emit("saved", reviewModel);
      }
    },
    cancel() {
      this.loading = false;
      this.$emit("cancel");
    },
  },
  computed: {
    selectedDate: {
      get(): string {
        return this.model.ReviewDate ? Utils.toVsDateFormat(this.model.ReviewDate) : "";
      },
      set(date: string | null): void {
        this.model.ReviewDate = Utils.vsDateToDatetime(date || "");
      },
    },
  },

  async mounted() {
    if (this.reviewObj) {
      if (this.reviewObj.ReviewDate) {
        this.model.ReviewDate = Utils.timestampToDate(this.reviewObj.ReviewDate);
      }
      this.model.Weakness = this.reviewObj.Weakness;
      this.model.Strength = this.reviewObj.Strength;
      this.model.Customer =
        this.reviewObj.Customer.FirstName || this.reviewObj.Customer.LastName || this.reviewObj.Customer.UserName ? "" : this.reviewObj.Customer;
      this.model.Rating = this.reviewObj.Rating;
      this.model.Text = this.reviewObj.Text;
    }
  },
});
</script>
<style scoped>
.custom-text {
  font-weight: bold;
  font-size: 1.9em;
  border: 1px solid #cfcfcf;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #999;
  background: #fff;
}
</style>
