<template>
  <v-card>
    <v-card-title>
      <div class="card-title">
        <div class="card-title-left">VM Support</div>
        <div class="card-title-right">
          <v-btn color="primary" dark @click="showDialog = true"> <v-icon>mdi-plus</v-icon> New Support Ticket</v-btn>
        </div>
      </div>
    </v-card-title>
    <v-card-text>
      <v-form ref="frmList" lazy-validation>
        <v-data-table ref="supportList" :items="supportList" :headers="headers" :loading="loading" item-key="Id">
          <template v-slot:item.Teammember="{ item }">
            {{ getTMName(item.OwnerId) }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-menu offset-y location="bottom" scroll-strategy="close">
              <template v-slot:activator="{ props }">
                <v-btn size="small" variant="text" color="black" v-bind="props" icon="mdi-dots-vertical" />
              </template>
              <v-list>
                <v-list-item @click="editVM(item)" prepend-icon="mdi-pencil-outline" title="Edit" />
                <v-list-item @click="showActivityWatch(item)" prepend-icon="mdi-chart-line" title="Show Activity Watch" />
                <v-list-item @click="showWazuhDashboard(item)" prepend-icon="mdi-shield-check" title="Show Wazuh Report" />
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-form>
    </v-card-text>
    <v-dialog width="auto" v-model="showDialog" @keypress.esc="showDialog = false">
      <CreateOrEditVM :teammembers="teammembers" @cancel="cancelRegister" @save="save" :vmModel="selectedVM"></CreateOrEditVM>
    </v-dialog>
    <v-dialog width="600" v-if="showMaintenanceDialog" v-model="showMaintenanceDialog" @keypress.esc="showMaintenanceDialog = false">
      <VdSupport
        :teammembers="teammembers"
        @cancel="cancelRegister"
        @createNotification="createNotification"
        :vmList="selectedVmList"
      ></VdSupport>
    </v-dialog>
  </v-card>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import ConfirmDialog from "shared-components/src/components/ConfirmDialog.vue";
import CreateOrEditVM from "./CreateOrEditVM.vue";
import VdSupport from "shared-components/src/components/Vm/VdSupport.vue";
import { VM } from "shared-components/src/models/VM";
import { NotificationApi } from "shared-components/src/services/openApi/api";
import store from "@/store";
import { NotificationModel } from "shared-components/src/services/openApi";

export interface VMUIModel extends VM {
  IsSelected: boolean | false;
}

export default defineComponent({
  components: { ConfirmDialog, CreateOrEditVM, VdSupport },
  async mounted() {
    await this.fetchSupportList();
  },
  data() {
    return {
      tmLoading: true,
      loading: false,
      showDialog: false,
      supportList: [] as NotificationModel[],
      headers: [
        { title: "Message", value: "Text" },
        { title: "Username", value: "Username" },
        { title: "Password", value: "Password" },
        { title: "Public IP", value: "PublicIP" },
        { title: "Private IP", value: "PrivateIP" },
        { title: "Action", value: "action" },
      ],
    };
  },
  computed: {
    isSupport(): boolean {
      return store.getters.userIsSupport ?? false;
    },
  },
  methods: {
    async fetchSupportList() {
      try {
        this.loading = true;
        const result = (await (new NotificationApi().getSupportNotifications())).data;
        this.supportList = result as NotificationModel[];
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.selected-row{
  background-color: red;
}
.vm-checkbox{
  height: 50px;
}
.vm-row:hover {
  cursor: pointer;
}
.custom-timesheet-row {
  padding: 10px !important;
  .col {
    overflow-wrap: anywhere;
    text-align: center;
    padding: 0 8px;
  }
  .timesheet-row {
    border: 1px solid #959595;
    border-radius: 10px;
    padding: 10px 0;
    margin: 10px 0;

    .description {
      text-align: left;
      margin: 0 20px;
    }
  }
}
</style>
