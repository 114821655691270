<template>
  <div>
    <wazuh-dashboard :vmId="vmId" />
  </div>
</template>
<script lang="ts">
import Vue, { defineComponent } from "vue";
import WazuhDashboard from "shared-components/src/components/Reports/Wazuh/Dashboard.vue";

export default defineComponent({
  components: {
    WazuhDashboard,
  },
  data() {
    return {
      vmId: this.$route.params.id, //this is the id from the browser
    };
  },
});
</script>
