import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "headline" }
const _hoisted_2 = { class: "task-status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_TextAreaField = _resolveComponent("TextAreaField")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            icon: "",
            onClick: _ctx.closeDialog,
            class: "ml-auto"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, null, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("mdi-close")
                ])),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, null, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.selectedRow.TaskTitle), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider),
          _createVNode(_component_v_container, { class: "py-4" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { align: "center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_avatar, {
                        color: "primary",
                        size: "12"
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, null, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.item.Status), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-details"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-subtitles")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { location: "top" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps(props, { class: "mr-4" }), _toDisplayString(_ctx.selectedRow.PositionTitle), 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createElementVNode("span", null, "Job Opening Name", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode("mdi-account-tie")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { location: "top" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps(props, { class: "mr-8" }), _toDisplayString(_ctx.selectedRow.HiringManagerName), 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[5] || (_cache[5] = _createElementVNode("span", null, "Hiring Manager Name", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, { class: "mr-1" }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode("mdi-calendar")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { location: "top" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(_ctx.item.Date), 17)
                        ]),
                        default: _withCtx(() => [
                          _cache[7] || (_cache[7] = _createElementVNode("span", null, "Date", -1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_icon, { class: "ml-4 mr-1" }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createTextVNode("mdi-format-list-bulleted")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_tooltip, { location: "top" }, {
                        activator: _withCtx(({ props }) => [
                          _createElementVNode("span", _mergeProps(props, { class: "mr-1" }), "Order ", 16),
                          _createElementVNode("span", null, _toDisplayString(_ctx.item.OrderNumber), 1)
                        ]),
                        default: _withCtx(() => [
                          _cache[9] || (_cache[9] = _createElementVNode("span", null, "Order Number", -1))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[10] || (_cache[10] = _createElementVNode("span", { class: "task-title" }, "Description", -1)),
                      _createElementVNode("p", null, _toDisplayString(_ctx.item.Description), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_v_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "task-section"
                  }, {
                    default: _withCtx(() => [
                      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "comment-part" }, [
                        _createElementVNode("span", { class: "task-title" }, "Comment")
                      ], -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: comment.id
                        }, [
                          _createElementVNode("p", null, _toDisplayString(comment.Text), 1)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { cols: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextAreaField, {
                        label: "Message",
                        type: "text",
                        modelValue: _ctx.message,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
                        outlined: ""
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "comment-button"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: _ctx.confirm
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_icon, null, {
                            default: _withCtx(() => _cache[12] || (_cache[12] = [
                              _createTextVNode("mdi-check")
                            ])),
                            _: 1
                          }),
                          _cache[13] || (_cache[13] = _createTextVNode(" Add Commnet "))
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}