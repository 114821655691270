import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Timesheet = _resolveComponent("Timesheet", true)!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createTextVNode(" Time Sheet List ")),
          _createVNode(_component_v_divider, {
            class: "mx-4",
            inset: "",
            vertical: ""
          }),
          _createVNode(_component_v_spacer),
          (_ctx.useAsModal)
            ? (_openBlock(), _createBlock(_component_v_btn, {
                key: 0,
                class: "error mt-1 mb-1",
                fab: "",
                "x-small": "",
                title: "Close",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("X")
                ])),
                _: 1
              }, 8, ["onClick"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_text, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12",
                sm: "12"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        class: "filters",
                        md: "4"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AutoCompleteField, {
                            loading: _ctx.fillTeammembersLoading,
                            modelValue: _ctx.selectedTeammember,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTeammember) = $event)),
                            items: _ctx.teammembers,
                            "item-title": "FullName",
                            "item-value": "Id",
                            outlined: "",
                            dense: "",
                            label: "Team Member",
                            "return-object": ""
                          }, {
                            item: _withCtx(({ item, index, props }) => [
                              _createVNode(_component_v_list, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_list_item, _mergeProps(props, {
                                    class: { 'txt-color': item.raw.isFirstInactive },
                                    title: item.raw.FullName
                                  }), null, 16, ["class", "title"])
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _: 1
                          }, 8, ["loading", "modelValue", "items"])
                        ]),
                        _: 1
                      }),
                      (_ctx.selectedTeammember)
                        ? (_openBlock(), _createBlock(_component_v_col, {
                            key: 0,
                            class: "filters",
                            md: "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AutoCompleteField, {
                                loading: _ctx.fillCommitmentsLoading,
                                modelValue: _ctx.selectedCommitment,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCommitment) = $event)),
                                items: _ctx.commitments,
                                "item-title": "Name",
                                "item-value": "Id",
                                outlined: "",
                                dense: "",
                                label: "Commitment",
                                "return-object": ""
                              }, {
                                item: _withCtx(({ item, index, props }) => [
                                  _createVNode(_component_v_list, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item, _mergeProps(props, {
                                        class: { 'txt-color': _ctx.isFirstInactive(item.raw.id) },
                                        title: item.raw.Name
                                      }), null, 16, ["class", "title"])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 1
                              }, 8, ["loading", "modelValue", "items"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_divider, {
            class: "mx-4",
            inset: "",
            horizontal: ""
          }),
          (_ctx.selectedTeammember && _ctx.shouldRender)
            ? (_openBlock(), _createBlock(_component_Timesheet, {
                key: 0,
                isAdmin: !_ctx.useForCustomer,
                isCustomer: _ctx.useForCustomer,
                timesheetsVersion: _ctx.timesheetsVersion,
                showRegister: _ctx.showRegister,
                dateOffset: _ctx.dateOffset,
                userProjects: _ctx.userProjects,
                selectedTeammember: _ctx.selectedTeammember,
                selectedCommitment: _ctx.selectedCommitment,
                fetchAllCommitmentsService: _ctx.fetchAllCommitments,
                fetchTimeSheetsService: _ctx.fetchTimesheets,
                fetchLeavesService: _ctx.fetchLeaves,
                finishedCloneProcess: _ctx.finishedCloneProcess,
                cloneTimesheetId: _ctx.cloneTimesheetId,
                regLoading: _ctx.regLoading,
                onRegisterTimesheet: _ctx.registerTimesheet,
                onConfirmTimesheet: _ctx.confirmTimesheet,
                onCancelRegister: _ctx.cancel,
                onErrorRaised: _ctx.errorRaised,
                onConfirmClone: _ctx.confirmClone,
                onDeleteLeave: _ctx.deleteLeave,
                onDeleteTimesheet: _ctx.deleteTimesheet,
                onConfirmLeave: _ctx.confirmLeave
              }, null, 8, ["isAdmin", "isCustomer", "timesheetsVersion", "showRegister", "dateOffset", "userProjects", "selectedTeammember", "selectedCommitment", "fetchAllCommitmentsService", "fetchTimeSheetsService", "fetchLeavesService", "finishedCloneProcess", "cloneTimesheetId", "regLoading", "onRegisterTimesheet", "onConfirmTimesheet", "onCancelRegister", "onErrorRaised", "onConfirmClone", "onDeleteLeave", "onDeleteTimesheet", "onConfirmLeave"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}