import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_AutoCompleteField = _resolveComponent("AutoCompleteField")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_data_table = _resolveComponent("v-data-table")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_NewTaskTemplate = _resolveComponent("NewTaskTemplate")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_container, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { justify: "center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "12"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_title, { class: "title" }, {
                      default: _withCtx(() => [
                        _cache[5] || (_cache[5] = _createElementVNode("span", { class: "headline" }, "Task List Template", -1)),
                        _createVNode(_component_v_btn, {
                          color: "primary",
                          onClick: _ctx.openNewTaskDialog
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_icon, { left: "" }, {
                              default: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode("mdi-plus")
                              ])),
                              _: 1
                            }),
                            _cache[4] || (_cache[4] = _createTextVNode(" New Task Template "))
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_spacer),
                    _createVNode(_component_v_container, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_col, {
                              cols: "12",
                              md: "6",
                              sm: "9",
                              xs: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AutoCompleteField, {
                                  modelValue: _ctx.taskListFilter,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.taskListFilter) = $event)),
                                  items: _ctx.taskList,
                                  "item-title": "Title",
                                  "item-value": "id",
                                  label: "Task",
                                  dense: "",
                                  outlined: "",
                                  placeholder: "Task",
                                  type: "text"
                                }, null, 8, ["modelValue", "items"])
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_v_col, {
                              class: "mt-8",
                              cols: "12",
                              md: "6",
                              xs: "12",
                              sm: "3"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_switch, {
                                  modelValue: _ctx.statusFilter,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statusFilter) = $event)),
                                  label: "Status",
                                  color: "primary"
                                }, null, 8, ["modelValue"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "filter"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_btn, { onClick: _ctx.clearFilter }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { left: "" }, {
                                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                                    _createTextVNode("mdi-close")
                                  ])),
                                  _: 1
                                }),
                                _cache[7] || (_cache[7] = _createTextVNode(" clear Filter "))
                              ]),
                              _: 1
                            }, 8, ["onClick"]),
                            _createVNode(_component_v_btn, {
                              color: "primary",
                              onClick: _ctx.filterTaskTemplate
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, { left: "" }, {
                                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                                    _createTextVNode("mdi-check")
                                  ])),
                                  _: 1
                                }),
                                _cache[9] || (_cache[9] = _createTextVNode(" Filter "))
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_data_table, {
                      headers: _ctx.headerTask,
                      items: _ctx.tableTasks,
                      class: "elevation-1 pr-5 pl-5",
                      "items-per-page": 10
                    }, {
                      "item.Status": _withCtx(({ item }) => [
                        _createTextVNode(_toDisplayString(_ctx.getStatusLabel(item.Status)), 1)
                      ]),
                      "item.Description": _withCtx(({ item }) => [
                        _createVNode(_component_v_tooltip, { location: "right" }, {
                          activator: _withCtx(({ props }) => [
                            _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), _toDisplayString(_ctx.truncate(item.Description)), 17)
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("span", null, _toDisplayString(item.Description), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      "item.actions": _withCtx(({ item }) => [
                        _createVNode(_component_v_menu, {
                          "offset-y": "",
                          left: "",
                          attach: ""
                        }, {
                          activator: _withCtx(({ props }) => [
                            _createVNode(_component_v_btn, _mergeProps({
                              "x-small": "",
                              icon: "",
                              color: "black"
                            }, props, { variant: "text" }), {
                              default: _withCtx(() => [
                                _createVNode(_component_v_icon, null, {
                                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createTextVNode("mdi-dots-vertical")
                                  ])),
                                  _: 1
                                })
                              ]),
                              _: 2
                            }, 1040)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list, { density: "compact" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dynamicActions(item), (actionItem, index) => {
                                  return (_openBlock(), _createBlock(_component_v_list_item, {
                                    key: index,
                                    onClick: ($event: any) => (actionItem.action(item)),
                                    color: "primary"
                                  }, {
                                    prepend: _withCtx(() => [
                                      _createVNode(_component_v_icon, {
                                        icon: actionItem.icon,
                                        class: "mr-1",
                                        color: "blue",
                                        small: ""
                                      }, null, 8, ["icon"])
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_v_list_item_title, {
                                        textContent: _toDisplayString(actionItem.text),
                                        class: "text-left"
                                      }, null, 8, ["textContent"])
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 1
                    }, 8, ["headers", "items"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.taskTemplateDialog)
          ? (_openBlock(), _createBlock(_component_v_dialog, {
              key: 0,
              modelValue: _ctx.taskTemplateDialog,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.taskTemplateDialog) = $event)),
              fullscreen: "",
              "hide-overlay": "",
              transition: "dialog-bottom-transition"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NewTaskTemplate, {
                  editingTask: _ctx.editingTask,
                  onTaskSaved: _ctx.addTaskToTable,
                  onCloseTaskDialog: _ctx.closeNewTaskDialog
                }, null, 8, ["editingTask", "onTaskSaved", "onCloseTaskDialog"])
              ]),
              _: 1
            }, 8, ["modelValue"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}