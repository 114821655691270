<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="12">
          <v-card>
            <v-card-title class="title">
              <span class="headline">Task List</span>
              <v-btn color="primary" @click="openNewTaskDialog">
                <v-icon left>mdi-plus</v-icon>
                New Task
              </v-btn>
            </v-card-title>

            <v-container>
              <v-row>
                <v-col cols="12" md="3" xs="12" sm="6">
                  <AutoCompleteField
                    item-title="Title"
                    item-value="Id"
                    v-model="namePositionFilter"
                    :items="namePosition"
                    dense
                    outlined
                    label="Job Opening"
                    placeholder="Job Opening"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
                <v-col cols="12" md="3" xs="12" sm="6">
                  <AutoCompleteField
                    item-title="Name"
                    item-value="Id"
                    v-model="taskTitleFilter"
                    :items="taskList"
                    dense
                    outlined
                    label="Task Title "
                    placeholder="Task Title"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
                <v-col cols="12" md="3" xs="12" sm="6">
                  <DateInputField
                    @click:clear="onDateClear"
                    clearable
                    first-day-of-week="1"
                    v-model="datefilter"
                    hide-actions
                    label="Date"
                    placeholder="Date"
                    prepend-icon=""
                  />
                </v-col>
                <v-col cols="12" md="3" xs="12" sm="6">
                  <AutoCompleteField
                    :items="hiringManagerList"
                    item-title="fullName"
                    item-value="id"
                    v-model="hiringManagerFilter"
                    dense
                    outlined
                    label="Hiring Manager"
                    placeholder="Hiring Manager"
                    type="text"
                  ></AutoCompleteField>
                </v-col>
              </v-row>

              <v-col cols="12" class="filter">
                <v-btn @click="clearFilter">
                  <v-icon left>mdi-close</v-icon>
                  clear Filter
                </v-btn>
                <v-btn color="primary" @click="filteredTasks">
                  <v-icon left>mdi-check</v-icon>
                  Filter
                </v-btn>
              </v-col>
            </v-container>
            <v-data-table :headers="headerTask" :items="tableTasks" class="elevation-1 pr-5 pl-5" :items-per-page="10">
              <template v-slot:item.actions="{ item }">
                <v-menu offset-y left attach>
                  <template v-slot:activator="{ props }">
                    <v-btn x-small icon v-bind="props" variant="text">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list density="compact">
                    <v-list-item v-for="(actionItem, index) in actions" :key="index" @click="actionItem.action(item)" color="primary">
                      <template v-slot:prepend>
                        <v-icon :icon="actionItem.icon" class="mr-1" color="blue" small></v-icon>
                      </template>
                      <v-list-item-title v-text="actionItem.text" class="text-left"></v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog max-width="800px" v-model="taskDialog" v-if="taskDialog" transition="dialog-bottom-transition">
        <TaskDetail :selectedRow="selectedRow" :isHiringmanager="isHiringmanager" :item="selectedTask" @close-dialog="closeDialog"></TaskDetail>
      </v-dialog>
      <v-dialog v-model="newTaskDialog" v-if="newTaskDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
        <NewTask
          :isHiringmanager="isHiringmanager"
          :is-editing="isEditing"
          :task="selectedTask"
          @TaskSaved="TaskSaved"
          @close-task-dialog="closeNewTaskDialog"
        ></NewTask>
      </v-dialog>
    </v-container>
  </div>
</template>
<script lang="ts">
import TaskDetail from "./TaskDetail.vue";
import TextField from "shared-components/src/components/Fields/TextField.vue";
import NewTask from "./NewTask.vue";
import {
  TaskTemplateApi,
  EntitySummaryModel,
  UserApi,
  PositionTaskResponseModel,
  PositionApi,
  NotificationApi,
  PositionTaskDetailResponseModel,
  PaginationRequestModel,
  TaskTemplateModel,
} from "shared-components/src/services/openApi/api";
import store from "@/store";
import { defineComponent } from "vue";
import Utils from "@/utils/Utils";

const positionApi = new PositionApi();
const notificationApi = new NotificationApi();
const userApi = new UserApi();
const taskApi = new TaskTemplateApi();
export default defineComponent({
  components: { TaskDetail, TextField, NewTask },
  data() {
    return {
      rules: {
        required: (value: any) => !!value || "Required.",
      },
      headerTask: [
        { title: "Job Opening Name", align: "start", value: "PositionTitle", sortable: false },
        { title: "Hiring Manager", align: "start", value: "HiringManagerName", sortable: false },
        { title: "Task Title", align: "center", value: "TaskTitle", sortable: false },
        { title: "Task Date", align: "center", value: "Date", sortable: false },
        { title: "Task Status", align: "center", value: "Status", sortable: false },
        { title: "Actions", align: "right", value: "actions", sortable: false },
      ],
      actions: [
        { text: "Edit", icon: "mdi-pencil", action: this.editTask },
        { text: "View", icon: "mdi-tooltip-text", action: this.viewTask },
      ],
      taskDialog: false,
      newTaskDialog: false,
      tableTasks: [] as PositionTaskResponseModel[],
      selectedTask: {} as PositionTaskDetailResponseModel,
      selectedRow: {} as PositionTaskDetailResponseModel,
      namePositionFilter: "",
      taskTitleFilter: "",
      startDateFilter: "",
      startDateMenu: false,
      isEditing: false,
      selectedDate: null as Date | null,
      datefilter: null as Date | null,
      hiringManagerFilter: "",
      tableOptions: {} as any,
      sortOrder: "desc",
      sortProp: "Status",
      hiringManagerList: [] as any,
      namePosition: [] as EntitySummaryModel[],
      taskList: [] as TaskTemplateModel[],
    };
  },
  async mounted() {
    await this.getHiringManagerList();
    await this.getTabelTaskData();
    await this.getComments();
    await this.getPositonSummeryList();
    await this.getTaskList();
  },
  watch: {
    tableOptions(newVal, oldValue) {
      if (oldValue && newVal != oldValue) {
        if (newVal.sortBy && newVal.sortBy.length) {
          this.sortProp = newVal.sortBy[0];
        }
        if (newVal.sortDesc && newVal.sortDesc.length) {
          this.sortOrder = newVal.sortDesc[0] ? "desc" : "Status";
        }
      }
    },
  },
  computed: {
    isHiringmanager(): boolean {
      return store.getters.userIsHiringmanager ?? false;
    },
    formattedTableTasks(): PositionTaskResponseModel[] {
      return this.tableTasks.map((task) => ({
        ...task,
        Date: this.formatDate(task.Date),
      }));
    },
  },
  methods: {
    async getTaskList() {
      this.taskList = (await taskApi.geTaskTemplateList()).data;
    },
    async getPositonSummeryList() {
      await positionApi.getPositionSummaryList().then((data) => {
        this.namePosition = data.data;
      });
    },
    async getHiringManagerList() {
      try {
        const response = await userApi.getUsersByRole("hiringmanager");
        this.hiringManagerList = response.data.map((manager: any) => ({
          id: manager.id,
          fullName: manager.name,
        }));
      } catch (error) {
        console.error("Error fetching hiring managers:", error);
      }
    },
    onDateClear() {
      this.datefilter = null;
    },
    async filteredTasks() {
      try {
        const { page, itemsPerPage } = this.tableOptions;
        var stringDate = "";
        if (this.datefilter) {
          stringDate = Utils.toVsDateFormat(this.datefilter);
        }

        var paginationModel = {
          pageNumber: page,
          pageSize: itemsPerPage,
          sortOrder: this.sortOrder,
          sortProp: this.sortProp,
          Filters: {
            "TaskTemplate.Name": this.taskTitleFilter,
            "Position.User.DisplayName": this.hiringManagerFilter,
            "Position.Title": this.namePositionFilter,
            Date: stringDate,
          },
        } as PaginationRequestModel;
        console.log(paginationModel);
        const response = await positionApi.gePositionTaskList(paginationModel);
        response.data.forEach((c: PositionTaskResponseModel) => (c.Date = c.Date ? Utils.toVsDateFormat(new Date(c.Date)) : ""));
        console.log("response.data.", response.data);
        this.tableTasks = response.data;
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    },
    formatDate(date?: string): string {
      if (!date) return "";
      return date.split("T")[0];
    },
    async viewTask(item: PositionTaskResponseModel) {
      const result = await positionApi.gePositionTaskDetail(item.Id as string);
      result.data.Date = result.data.Date ? Utils.toVsDateFormat(new Date(result.data.Date)) : "";
      this.selectedRow = item;
      this.selectedTask = result.data;
      this.taskDialog = true;
    },
    closeDialog() {
      this.taskDialog = false;
    },
    closeNewTaskDialog() {
      this.newTaskDialog = false;
      this.isEditing = false;
    },
    openNewTaskDialog() {
      this.selectedTask = {};
      this.isEditing = false;
      this.newTaskDialog = true;
    },
    async getTabelTaskData() {
      try {
        const { page, itemsPerPage } = this.tableOptions;
        const model = {
          PageNumber: page,
          PageSize: itemsPerPage,
          SortOrder: this.sortOrder,
          SortProp: this.sortProp,
        } as PaginationRequestModel;
        console.log("model", model);
        const response = await positionApi.gePositionTaskList(model);
        response.data.forEach((c: PositionTaskResponseModel) => (c.Date = c.Date ? Utils.toVsDateFormat(new Date(c.Date)) : ""));
        this.tableTasks = response.data;
      } catch (error) {
        console.error("Error fetching task data:", error);
      }
    },
    async getComments() {
      try {
        const response = await notificationApi.getPositionTaskNotifications("PositionId");
      } catch (error) {
        console.error("Error fetching comment data:", error);
      }
    },
    TaskSaved(response: PositionTaskResponseModel) {
      if (response && response.Id) {
        response.Date = response.Date ? Utils.toVsDateFormat(new Date(response.Date)) : "";
        const finededIndex = this.tableTasks.findIndex((item) => item.Id == response.Id);
        if (finededIndex == -1) {
          this.tableTasks.unshift(response);
        } else {
          this.tableTasks.splice(finededIndex, 1, response);
        }
      } else {
        console.error("Invalid response:", response);
      }

      this.closeNewTaskDialog();
    },
    async editTask(item: PositionTaskResponseModel) {
      const result = await positionApi.gePositionTaskDetail(item.Id as string);
      this.selectedTask = result.data;
      this.newTaskDialog = true;
      this.isEditing = true;
    },
    clearFilter() {
      this.namePositionFilter = "";
      this.taskTitleFilter = "";
      this.datefilter = null;
      this.hiringManagerFilter = "";

      this.filteredTasks();
    },
  },
});
</script>
<style scoped>
.headline {
  font-weight: bold;
  padding-left: 8px;
}
.title {
  display: flex;
  justify-content: space-between;
}
.filter {
  gap: 8px;
  display: flex;
  justify-content: flex-end;
}
</style>
